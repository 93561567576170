<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18.828"
    height="18.796"
    viewBox="0 0 18.828 18.796"
  >
    <g id="_18254207941535351492" transform="translate(-6.15 -6.15)">
      <g id="Group_577" transform="translate(6.4 6.4)">
        <path
          id="Path_555"
          d="M42.1 43.181a.647.647 0 0 0 .914 0l4.255-4.255a.418.418 0 0 0 .079-.095.055.055 0 0 0 .016-.032.344.344 0 0 1 .047-.079c0-.016 0-.032.016-.032.016-.032.016-.047.032-.079a.512.512 0 0 0 0-.252.12.12 0 0 0-.032-.079c0-.016 0-.032-.016-.032l-.047-.095a.015.015 0 0 0-.016-.016.418.418 0 0 0-.079-.095l-4.255-4.255a.646.646 0 0 0-.914.914l3.152 3.152h-11.2a.646.646 0 0 0 0 1.292h11.2L42.1 42.3a.609.609 0 0 0 0 .881z"
          class="cls-1"
          transform="translate(-29.145 -29.314)"
        />
        <path
          id="Path_556"
          d="M7.046 24.7h8.51a.639.639 0 0 0 .646-.646V17.7a.646.646 0 0 0-1.292 0v5.7H7.692V7.692h7.218v5.7a.646.646 0 0 0 1.292 0V7.046a.639.639 0 0 0-.646-.646h-8.51a.639.639 0 0 0-.646.646v17a.659.659 0 0 0 .646.654z"
          class="cls-1"
          transform="translate(-6.4 -6.4)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Logout",
};
</script>

<style scoped>
.cls-1 {
  fill: #666;
  stroke: #707070;
  stroke-width: 0.5px;
}
</style>
