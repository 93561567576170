<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16.609"
    height="20.033"
    viewBox="0 0 16.609 20.033"
  >
    <path
      d="M863 907.491a.06.06 0 0 0-.061.058v.471a.061.061 0 0 0 .061.058h5.478l.036.214a2.878 2.878 0 0 0 5.565 0l.036-.214h5.372a.061.061 0 0 0 .061-.058v-.471a.061.061 0 0 0-.061-.058h-.587l-.055-.181a17.674 17.674 0 0 1-.677-3.91v-.027c.017-.276.026-.552.026-.821 0-4.18-1.95-7.856-4.851-9.149l-.182-.081.034-.2a2.6 2.6 0 0 0 .038-.446 2.009 2.009 0 1 0-3.98 0 2.623 2.623 0 0 0 .031.4l.031.2-.184.077c-2.924 1.226-4.966 5.009-4.966 9.2 0 .268.009.544.026.821a12.262 12.262 0 0 1-.484 3.937l-.055.181z"
      transform="translate(-862.934 -890.4)"
    />
  </svg>
</template>

<script>
export default {
  name: "Bell",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  fill: currentColor;
}
</style>
