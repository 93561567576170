<template>
  <v-dialog
    v-model="show_dialog"
    v-if="error"
    width="500"
    content-class="br-16"
  >
    <v-card>
      <v-card-title class="text-left primary white--text font-weight-bold pb-4">
        <span
          ><v-icon class="mr-2" color="yellow">mdi-alert</v-icon>Ooops...</span
        >
      </v-card-title>

      <v-card-text v-if="error.message" v-html="error.message" />
      <v-card-text
        v-else-if="error.detail"
        v-html="(error.detail && error.detail.message) || error.detail"
      />
      <v-card-text v-else>
        Something went wrong, please try again. If problem persist please
        contact support
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text v-if="pretty_voucher_excluded_categories">
        This voucher does not apply for
        <b>{{ pretty_voucher_excluded_categories }}</b> products.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="error.to"
          color="primary"
          text
          rounded
          @click="ignore_route_guard"
        >
          Continue anyway
        </v-btn>
        <v-btn color="primary" rounded text @click="set_error({ error: null })">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "ErrorDialog",
  data() {
    return {
      show_dialog: false,
    };
  },
  watch: {
    error: function (error) {
      this.show_dialog = error !== null;
    },
  },
  computed: {
    ...mapState("ErrorStore", ["error"]),
    ...mapGetters("ShopStore", ["all_shop_product_categories"]),
    pretty_voucher_excluded_categories: function () {
      let cats = this.error.excluded_categories;
      if (cats && Object.keys(cats).length) {
        return Object.keys(cats)
          .map((cat) => this.all_shop_product_categories[cat])
          .join(", ");
      }
      return null;
    },
  },
  methods: {
    ...mapActions("ErrorStore", [
      "set_error",
      "toggle_ignore_before_route_leave_guard",
    ]),
    ignore_route_guard() {
      const to = this.error.to;
      this.toggle_ignore_before_route_leave_guard();
      this.$router.push({ path: to });
      this.set_error({ error: null });
      setTimeout(() => {
        this.toggle_ignore_before_route_leave_guard();
      }, 500);
    },
  },
};
</script>
