import firebase from "firebase/app";
import "firebase/auth";
import store from "@/store/index";
import admin_store from "@/store/AdminStore";
import StaffStore from "@/store/StaffStore";
import UserStore from "@/store/UserStore";

// TODO: refactor how storage is used (syntax) and where
async function shop_admin_guard(to, from, next) {
  const user = await firebase.auth().currentUser;

  if (user) {
    const id_token_result = await user.getIdTokenResult();
    if (
      id_token_result.claims["shop_admin"] ||
      id_token_result.claims["shop_staff"]
    ) {
      const shop_id = id_token_result.claims["shop_id"];
      if (!admin_store.state.shop) {
        await Promise.all([
          store.dispatch("AdminStore/bind_admin_data", { shop_id }),
          store.dispatch("ShopStore/get_shop", shop_id),
        ]);
      }
      next();
      return;
    }
    if (
      id_token_result.claims["super_admin"] ||
      id_token_result.claims["brand_manager"]
    ) {
      if (!admin_store.state.shop) {
        next({ name: "admin-brands" });
      } else {
        next();
      }
      return;
    }
  } else if (!["/", "/login"].includes(to.path)) {
    let next_url = to.path;
    if (to.query) {
      next_url += "?";
      next_url += Object.keys(to.query)
        .map((key) => `${key}=${to.query[key]}`)
        .join("&");
    }
    next({ path: "/login", query: { next_url } });
    return;
  } else {
    next("/login");
  }
}

function staff_role_guard(to, from, next) {
  const required_staff_permission = to.meta?.staff_permission ?? [];
  const active_staff = StaffStore.state.logged_in_staff;
  const is_shop_admin = UserStore.state.is_shop_admin;
  const is_super_admin = UserStore.state.is_super_admin;
  const is_community_admin = UserStore.state.is_community_admin;

  if (is_shop_admin || is_super_admin || is_community_admin) {
    next();
    return;
  } else if (!active_staff) {
    next("/staff_login");
    return;
  } else if (
    active_staff &&
    required_staff_permission &&
    !required_staff_permission.includes(active_staff.role)
  ) {
    store.dispatch("ErrorStore/set_error", {
      error: { message: `You are not authorized to access ${to.name} page.` },
    });
    return;
  }
  next();
}

export const SHOP_ADMIN_ROUTE = {
  path: "/",
  name: "Shop Admin",
  component: () =>
    import(/* webpackChunkName: "shop_admin" */ "@/views/dashboard/Index"),
  beforeEnter: shop_admin_guard,
  children: [
    {
      path: "retail",
      name: "retail-orders",
      meta: {
        staff_permission: ["staff", "manager", "admin"],
        breadcrumb: [
          { name: "Retail", to_name: "retail-orders" },
          { name: "My Orders" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "orders_admin" */ "@/views/dashboard/tables/Orders"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "hospitality",
      name: "My Orders",
      meta: {
        staff_permission: ["staff", "manager", "admin"],
        breadcrumb: [{ name: "My Orders" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "in_house_admin" */ "@/views/dashboard/tables/DiningOrders"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "planner",
      name: "Delivery Planner",
      meta: {
        staff_permission: ["staff", "delivery_staff", "manager", "admin"],
        breadcrumb: [
          { name: "Retail", to_name: "retail-orders" },
          { name: "Delivery Planner" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "delivery_planner_admin" */ "@/views/dashboard/pages/DeliveryPlanner"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "deliveries",
      name: "Deliveries",
      meta: {
        staff_permission: ["staff", "delivery_staff", "manager", "admin"],
        breadcrumb: [
          { name: "Retail", to_name: "retail-orders" },
          { name: "Deliveries" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "deliveries_admin" */ "@/views/dashboard/pages/Deliveries"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "reports",
      name: "Reports",
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [{ name: "Reports" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "deliveries_admin" */ "@/views/dashboard/pages/Reports"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "products",
      name: "products",
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [{ name: "Products" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "products_admin" */ "@/views/dashboard/tables/Products"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "booking",
      name: "booking-settings",
      redirect: "/booking/general",
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [
          { name: "Table Booking" },
          { name: "Settings" },
          { name: "General" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "booking-settings" */ "@/views/dashboard/pages/table_bookings/settings/index"
        ),
      children: [
        {
          path: "general",
          name: "Booking Settings",
          component: () =>
            import("@/views/dashboard/pages/table_bookings/settings/general"),
          meta: {
            staff_permission: ["manager", "admin"],
            breadcrumb: [
              { name: "Table Booking", to_name: "booking-timeline" },
              { name: "Settings", to_name: "booking-settings" },
              { name: "General" },
            ],
          },
        },
        {
          path: "working-hours",
          name: "Booking Hours",
          component: () =>
            import(
              "@/views/dashboard/pages/table_bookings/settings/working_hours"
            ),
          meta: {
            staff_permission: ["manager", "admin"],
            breadcrumb: [
              { name: "Table Booking", to_name: "booking-timeline" },
              { name: "Settings", to_name: "booking-settings" },
              { name: "Opening Hours" },
            ],
          },
        },
        {
          path: "tables",
          name: "Booking Tables",
          component: () =>
            import("@/views/dashboard/pages/table_bookings/settings/tables"),
          meta: {
            staff_permission: ["manager", "admin"],
            breadcrumb: [
              { name: "Table Booking", to_name: "booking-timeline" },
              { name: "Settings", to_name: "booking-settings" },
              { name: "Tables Setup" },
            ],
          },
        },
        {
          path: "gallery",
          name: "Gallery",
          component: () =>
            import("@/views/dashboard/pages/table_bookings/settings/gallery"),
          meta: {
            staff_permission: ["manager", "admin"],
            breadcrumb: [
              { name: "Table Booking", to_name: "booking-timeline" },
              { name: "Settings", to_name: "booking-settings" },
              { name: "Gallery" },
            ],
          },
        },
        {
          path: "widget",
          name: "Widget",
          component: () =>
            import("@/views/dashboard/pages/table_bookings/settings/widget"),
          meta: {
            staff_permission: ["manager", "admin"],
            breadcrumb: [
              { name: "Table Booking", to_name: "booking-timeline" },
              { name: "Settings", to_name: "booking-settings" },
              { name: "Widget" },
            ],
          },
        },
      ],
    },
    {
      path: "booking-timeline",
      name: "booking-timeline",
      component: () => import("@/views/dashboard/pages/table_bookings/today"),
      beforeEnter: staff_role_guard,
      meta: {
        staff_permission: ["staff", "manager", "admin"],
        breadcrumb: [
          { name: "Table Booking", to_name: "booking-timeline" },
          { name: "My Bookings" },
        ],
      },
    },
    {
      path: "management",
      name: "Manage All Bookings",
      component: () =>
        import("@/views/dashboard/pages/table_bookings/management"),
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [
          { name: "Table Booking", to_name: "booking-timeline" },
          { name: "Manage All Bookings" },
        ],
      },
    },
    {
      path: "reviews",
      name: "Customer Reviews",
      component: () => import("@/views/dashboard/pages/table_bookings/reviews"),
      meta: {
        staff_permission: ["staff", "manager", "admin"],
        breadcrumb: [
          { name: "Table Booking", to_name: "booking-timeline" },
          { name: "Customer Reviews" },
        ],
      },
    },
    {
      path: "categories",
      name: "Categories",
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [
          { name: "Products", to_name: "products" },
          { name: "Categories" },
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "categories_admin" */ "@/views/dashboard/tables/categories/Categories"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "vouchers",
      name: "Vouchers",
      meta: {
        staff_permission: ["admin"],
        breadcrumb: [{ name: "Vouchers" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "vouchers_admin" */ "@/views/dashboard/tables/Vouchers"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "staff",
      name: "Staff",
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [{ name: "Staff" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "staff_admin" */ "@/views/dashboard/tables/Staff"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "settings",
      name: "Settings",
      meta: {
        staff_permission: ["manager", "admin"],
        breadcrumb: [{ name: "Settings" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "settings_admin" */ "@/views/dashboard/pages/Settings"
        ),
      beforeEnter: staff_role_guard,
    },
    {
      path: "advertising",
      name: "Advertising",
      meta: {
        staff_permission: ["staff", "manager", "admin"],
        breadcrumb: [{ name: "Advertising" }],
      },
      component: () =>
        import(
          /* webpackChunkName: "advertising" */ "@/views/dashboard/pages/Advertising"
        ),
      beforeEnter: staff_role_guard,
    },
  ],
};
