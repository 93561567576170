<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="17.683"
    height="16.811"
    viewBox="0 0 17.683 16.811"
  >
    <g id="_19136822361582884291" transform="translate(-2.615 -5.642)">
      <path
        id="Path_667"
        d="M18.631 5.642h-5.438l1.566 1.567L8.446 14.3l1.523 1.523 7.1-6.313 1.567 1.567z"
        class="cls-1 fillable"
        transform="translate(-4.991)"
      />
      <path
        id="Path_668"
        d="M94.952 22.44V5.642h4.383v16.8z"
        class="cls-1 fillable"
        transform="translate(-79.037)"
      />
      <path
        id="Path_669"
        d="M53.142 73.456h-4.378v-8.228h4.378z"
        class="cls-1 fillable"
        transform="translate(-39.502 -51.003)"
      />
      <path
        id="Path_670"
        d="M2.615 89.9h4.36v4.664h-4.36z"
        class="cls-1 fillable"
        transform="translate(0 -72.123)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Reports",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  path {
    fill: currentColor;
  }
}
</style>
