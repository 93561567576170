<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10.018"
    height="12.267"
    viewBox="-1 -0.5 12.018 15.267"
    class="custom-icon"
  >
    <path
      d="M6.308 4.191a1.287 1.287 0 1 1 1.82 0 1.288 1.288 0 0 1-1.82 0zM5.286 11V5.856a.643.643 0 0 0-1.286 0V11a3.216 3.216 0 0 0 3.215 3.215h3.215a.643.643 0 1 0 0-1.286H7.215A1.926 1.926 0 0 1 5.286 11zm8.539 2.154L11.4 10.73a1.285 1.285 0 0 0-.907-.373h-1.67V7.991a6.65 6.65 0 0 0 2.7 1.3.684.684 0 1 0 .244-1.344 4.411 4.411 0 0 1-2.411-1.268l-.9-1a1.38 1.38 0 0 0-.444-.322 1.431 1.431 0 0 0-.612-.144h-.024A1.449 1.449 0 0 0 5.929 6.66v3.7a1.926 1.926 0 0 0 1.929 1.929h3.26l1.788 1.788a.656.656 0 0 0 .92 0 .644.644 0 0 0 0-.92z"
      transform="translate(-4 -1.995)"
      style="fill: #54c4c9"
    />
  </svg>
</template>

<script>
export default {
  name: "Seated",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  path {
    fill: currentColor;
  }
}
</style>
