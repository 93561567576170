<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.395"
    height="12.395"
    class="custom-icon"
  >
    <path
      data-name="19368965281560146000"
      d="M5.049 5.049h6.1a.61.61 0 1 1 0 1.22h-6.1a.61.61 0 1 1 0-1.22zm0 2.439h6.1a.61.61 0 1 1 0 1.22h-6.1a.61.61 0 1 1 0-1.22zM3.829 2h8.537A1.829 1.829 0 0 1 14.2 3.829v6.1a1.829 1.829 0 0 1-1.829 1.829H5.3l-2.257 2.26a.61.61 0 0 1-.433.177.512.512 0 0 1-.232-.049.61.61 0 0 1-.378-.561V3.829A1.829 1.829 0 0 1 3.829 2zM3.22 12.116l1.4-1.4a.61.61 0 0 1 .433-.177h7.317a.61.61 0 0 0 .61-.61v-6.1a.61.61 0 0 0-.61-.61H3.829a.61.61 0 0 0-.61.61z"
      transform="translate(4, 4)"
    />
  </svg>
</template>

<script>
export default {
  name: "Notes",
};
</script>
<style lang="scss" scoped>
.custom-icon {
  outline: none;
  path {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0.2px;
  }
}
</style>
