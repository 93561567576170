<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.484"
    height="16.46"
    viewBox="0 0 16.484 16.46"
  >
    <g id="_2468991631580817585" transform="translate(0 -.035)" class="cls-1">
      <path
        class="fillable"
        id="Path_538"
        d="M14.747 3.445H12.3a4.121 4.121 0 0 0-8.119 0H1.738A1.738 1.738 0 0 0 0 5.183v9.575A1.738 1.738 0 0 0 1.738 16.5h10.625a.687.687 0 1 0 0-1.374H1.738a.364.364 0 0 1-.364-.364V5.183a.364.364 0 0 1 .364-.364h13.009a.364.364 0 0 1 .364.364v7.191a.687.687 0 0 0 1.374 0V5.183a1.738 1.738 0 0 0-1.738-1.738zm-6.5-2.061a2.747 2.747 0 0 1 2.651 2.061h-5.3a2.747 2.747 0 0 1 2.644-2.06z"
      />
      <path
        class="fillable"
        id="Path_539"
        d="M17.7 16.732a3.111 3.111 0 1 0-.968.968l1.648 1.655a.69.69 0 1 0 .975-.975zm-4.327-1.642a1.717 1.717 0 1 1 1.717 1.717 1.717 1.717 0 0 1-1.716-1.716z"
        transform="translate(-3.758 -3.747)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Products",
};
</script>

<style scoped>
.cls-1 path {
  fill: #666;
}
</style>
