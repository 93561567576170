<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12.472"
    height="16.886"
    viewBox="0 0 12.472 16.886"
  >
    <path
      d="M178.139 137.235h-2.157v-.8a.628.628 0 0 0-.625-.628h-3.807a.632.632 0 0 0-.626.628v.8h-2.169a1.551 1.551 0 0 0-1.545 1.545v.976h12.472v-.974a1.55 1.55 0 0 0-1.543-1.547zm-6.477-.51c0-.244-.015-.23.23-.23h3.128c.245 0 .23-.015.23.23v.51h-3.587zm-3.674 14.615a1.358 1.358 0 0 0 1.352 1.356h8.219a1.358 1.358 0 0 0 1.352-1.356V140.8h-10.923zm7.889-8.3h1.241v7.417h-1.241zm-3.049 0h1.24v7.417h-1.24zm-3.049 0h1.241v7.417h-1.241z"
      transform="translate(-167.209 -135.81)"
    />
  </svg>
</template>

<script>
export default {
  name: "Delete",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  fill: currentColor;
}
</style>
