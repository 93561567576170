<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11.8"
    height="13.882"
    viewBox="-1 -1 15.8 16.882"
    class="custom-icon"
  >
    <path
      d="M5.562 2A1.562 1.562 0 0 0 4 3.562v10.759a1.562 1.562 0 0 0 1.562 1.562h5.206a1.562 1.562 0 0 0 1.562-1.547V13.28a.521.521 0 0 0-.521-.521.987.987 0 0 1-.65-.2 1.2 1.2 0 0 1-.354-.548 5.814 5.814 0 0 1-.21-1.86.521.521 0 0 0-.14-.355l-.114-.123q-.056-.063-.118-.12a70.05 70.05 0 0 1-.822-.811 2.09 2.09 0 0 1-.564-.811.427.427 0 0 1-.01-.226.6.6 0 0 1 .365-.366.425.425 0 0 1 .225.011 2.09 2.09 0 0 1 .811.566c.375.375.73.726 1.049 1.041.528.522.954.944 1.194 1.2a.521.521 0 0 0 .762-.71q-.444-.464-.9-.912V6.206L14.3 8.179a1.561 1.561 0 0 1 .457 1.1v6.079a.521.521 0 1 0 1.041 0V9.283a2.6 2.6 0 0 0-.762-1.841l-2.707-2.708V3.561A1.562 1.562 0 0 0 10.768 2zm4.944 11.375a1.881 1.881 0 0 0 .782.371v.054h-.521a.521.521 0 0 0-.521.521v.521h-1.04v-.521a1.561 1.561 0 0 1 .885-1.408 1.991 1.991 0 0 0 .415.462zm-.952-3.014c0 .176.006.365.016.562a2.429 2.429 0 1 1-1.076-4.39 1.709 1.709 0 0 0-.263.217 1.524 1.524 0 0 0-.414.7 1.46 1.46 0 0 0 .017.766 3.075 3.075 0 0 0 .831 1.265q.424.429.859.846l.025.027zm-3.472-7.32h1.042v.521a1.562 1.562 0 0 1-1.562 1.562h-.521V4.082h.521a.521.521 0 0 0 .521-.521zm3.124 0h1.041v.521a.521.521 0 0 0 .521.521h.521v1.041h-.521a1.562 1.562 0 0 1-1.562-1.562zm-2.082 11.8H6.082v-.521a.521.521 0 0 0-.521-.521h-.52v-1.04h.521a1.562 1.562 0 0 1 1.562 1.562z"
      transform="translate(-4 -2)"
      style="fill: #54c4c9"
    />
  </svg>
</template>

<script>
export default {
  name: "HandMoney",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  path {
    fill: currentColor;
  }
}
</style>
