<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16.91"
    height="16.91"
    viewBox="0 0 16.91 16.91"
  >
    <g id="_5899562021543238878" transform="translate(.849 .85)">
      <path
        id="Path_715"
        d="M15.689 12.107v4.061a1.521 1.521 0 0 1-1.521 1.521H3.521A1.521 1.521 0 0 1 2 16.168V5.521A1.521 1.521 0 0 1 3.521 4h4.061"
        class="cls-1"
        transform="translate(-1.999 -2.479)"
      />
      <path
        id="Path_716"
        d="M15.605 2l3.042 3.042-7.605 7.605H8V9.605z"
        class="cls-1"
        transform="translate(-3.436 -2)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Edit",
};
</script>

<style lang="scss" scoped>
.custom-icon g path {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.7px;
}
</style>
