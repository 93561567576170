<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.306"
    height="14.306"
    viewBox="0 0 14.306 14.306"
    class="custom-icon"
  >
    <circle cx="7.153" cy="7.153" r="7.153" fill="#54c4c9" />
    <path
      fill="#fff"
      stroke="#fff"
      stroke-width="0.2px"
      d="M7.746 3.915H3.915a.383.383 0 0 0 0 .766h3.83a.383.383 0 0 0 0-.766zm0 1.532H3.915a.383.383 0 0 0 0 .766h3.83a.383.383 0 0 0 0-.766zM8.512 2H3.149A1.149 1.149 0 0 0 2 3.149V6.98a1.149 1.149 0 0 0 1.149 1.149h4.44L9.006 9.55a.383.383 0 0 0 .272.111.322.322 0 0 0 .146-.031.383.383 0 0 0 .237-.352V3.149A1.149 1.149 0 0 0 8.512 2zm.383 6.355l-.877-.881a.383.383 0 0 0-.272-.111h-4.6a.383.383 0 0 1-.383-.383V3.149a.383.383 0 0 1 .383-.383h5.366a.383.383 0 0 1 .383.383z"
      transform="translate(1.322 1.413)"
    />
  </svg>
</template>

<script>
export default {
  name: "Seated",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  path {
    fill: currentColor;
  }
}
</style>
