import axios from "axios";
import store from "@/store/index";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const error_data = error.response.data?.error || error.response.data;
      const display_to_user = error_data?.detail?.display_to_user ?? true;

      if (display_to_user) {
        store.dispatch("ErrorStore/set_error", { error: error_data });
      }
    } else if (error.request) {
      console.error(
        "The request was made and the server responded with a status code" +
          "that falls out of the range of 2xx"
      );
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      store.dispatch("ErrorStore/set_error", {
        error: {
          message:
            "We encountered an error when making a network request. Please check your internet connection and try again",
        },
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error("Error", error.message);
    }
    return Promise.reject(error);
  }
);
