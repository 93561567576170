<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19.275"
    height="17.848"
    viewBox="0 0 19.275 17.848"
  >
    <g id="_6635486791578982984" transform="translate(.102 -19.012)">
      <g id="Group_568" transform="translate(6.319 33.406)">
        <g id="Group_567">
          <path
            id="Path_545"
            d="M171.333 402.828a1.677 1.677 0 1 0 1.677 1.677 1.679 1.679 0 0 0-1.677-1.677zm0 2.418a.741.741 0 1 1 .741-.741.742.742 0 0 1-.741.741z"
            class="cls-1"
            transform="translate(-169.656 -402.828)"
          />
        </g>
      </g>
      <g id="Group_570" transform="translate(12.202 33.406)">
        <g id="Group_569">
          <path
            id="Path_546"
            d="M329.278 402.828a1.677 1.677 0 1 0 1.677 1.677 1.679 1.679 0 0 0-1.677-1.677zm0 2.418a.741.741 0 1 1 .741-.741.742.742 0 0 1-.741.741z"
            class="cls-1"
            transform="translate(-327.601 -402.828)"
          />
        </g>
      </g>
      <g id="Group_572" transform="translate(7.114 24.576)">
        <g id="Group_571">
          <path
            id="Path_547"
            d="M198.174 165.755h-6.71a.468.468 0 1 0 0 .936h6.71a.468.468 0 1 0 0-.936z"
            class="cls-1"
            transform="translate(-190.996 -165.755)"
          />
        </g>
      </g>
      <g id="Group_574" transform="translate(7.479 27.007)">
        <g id="Group_573">
          <path
            id="Path_548"
            d="M207.25 231.035h-5.98a.468.468 0 0 0 0 .936h5.98a.468.468 0 0 0 0-.936z"
            class="cls-1"
            transform="translate(-200.802 -231.035)"
          />
        </g>
      </g>
      <g id="Group_576" transform="translate(0 19.114)">
        <g id="Group_575">
          <path
            id="Path_549"
            d="M18.859 22.49a.933.933 0 0 0-.726-.345H3.548l-.295-1.43a.938.938 0 0 0-.544-.67l-2.055-.892a.468.468 0 0 0-.373.859l2.055.892L4.6 31.9a.94.94 0 0 0 .917.747h11.22a.468.468 0 0 0 0-.936H5.52l-.276-1.338h11.58a.94.94 0 0 0 .917-.747l1.31-6.357a.933.933 0 0 0-.192-.779zm-2.036 6.949H5.051l-1.31-6.357h14.392z"
            class="cls-1"
            transform="translate(0 -19.114)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "RetailOrders",
};
</script>
<style scoped>
.cls-1 {
  fill: #666;
  stroke: #666;
  stroke-width: 0.2px;
}
</style>
