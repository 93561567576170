import axios from "axios";
import moment from "moment";

const DEBUG = process.env.NODE_ENV !== "production";
const DATE_FORMAT = "YYYY-MM-DD";
const DATETIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

const RealtimeStatus = {
  CONNECTED: "connected",
  CONNECTING: "connecting",
  DISCONNECTED: "disconnected",
};

const Operations = {
  INSERT: "INSERT",
  UPDATE: "UPDATE",
};

let socket;
let reconnect_interval;
let ping_timeout;
let ping_interval;
let messages_queue = [];
let is_processing_queue = false;

let ping = () => {
  socket.send(JSON.stringify({ text: "ping" }));

  // Close websocket after 5s wait, try to reconnect
  ping_timeout = setTimeout(() => {
    socket.close(1000);
    clearInterval(ping_interval);
  }, 5000);
};

const start_process_queue = async (dispatch, rootState) => {
  is_processing_queue = true;
  while (messages_queue.length > 0) {
    const message = messages_queue.shift();
    switch (message.table) {
      case "bookings":
        await on_bookings_event(message, dispatch, rootState);
        break;
      case "services":
        await on_services_event(message, dispatch);
        break;
      case "stripe_connects":
        await on_stripe_connect_event(message, dispatch);
        break;
      default:
        return;
    }
  }
  is_processing_queue = false;
};

function onopen() {
  const auth = axios.defaults.headers.common["Authorization"];
  socket.send(JSON.stringify({ auth }));

  // 60s health-checks
  ping_interval = setInterval(() => {
    ping();
  }, 60000);
}
function onclose(event, dispatch, commit, rootState) {
  if (ping_timeout) clearTimeout(ping_timeout);
  if (ping_interval) clearInterval(ping_interval);
  if (
    [
      4000, // Application disconnect
      1008, // Policy violation
    ].includes(event.code)
  ) {
    return; // Disable reconnecting
  }
  commit("SET_STATUS", RealtimeStatus.DISCONNECTED);

  if (event.wasClean) {
    DEBUG &&
      console.log(
        `[WS] Connection closed cleanly, code=${event.code} reason=${event.reason}`
      );
  } else {
    console.log("[WS] Connection died");
  }

  // reconnect every 15s
  reconnect_interval = setInterval(() => {
    DEBUG && console.log("[WS] Attempting to reconnect");
    dispatch("connect", rootState.AdminStore.shop_id);
  }, 15000);
}

async function onmessage(event, dispatch, rootState) {
  let event_payload;
  try {
    event_payload = JSON.parse(event.data);
  } catch (error) {
    console.log("[WS]", error);
    return;
  }
  DEBUG && console.log("[WS]", event_payload);
  if (event_payload?.text === "pong" ?? false) {
    clearTimeout(ping_timeout);
    return;
  }

  if (!event_payload.table) return;
  messages_queue.push(event_payload);

  if (is_processing_queue) return;

  await start_process_queue(dispatch, rootState);
}

async function on_bookings_event(event, dispatch, rootState) {
  const current_date = rootState.TableBookingStore.selected_booking_date;
  switch (event.operation) {
    case Operations.INSERT:
      if (
        Object.keys(rootState.TableBookingStore.datatable_filters).includes(
          "start_gte"
        )
      ) {
        dispatch("TableBookingStore/set_datatable_out_of_date", true, {
          root: true,
        });
      }
      // if (
      //   moment().format(DATE_FORMAT) ==
      //   moment(event.data.start_at).format(DATE_FORMAT)
      // )
      //   dispatch(
      //     "TableBookingStore/get_available_walk_in_dates",
      //     rootState.AdminStore.shop_id,
      //     { root: true }
      //   );
      if (
        current_date !==
        moment(event.data.start_at, DATETIME_FORMAT).format(DATE_FORMAT)
      )
        return;
      await dispatch(
        "TableBookingStore/get_booking",
        { booking_id: event.data.id, update_bookings: true },
        { root: true }
      );
      break;
    case Operations.UPDATE:
      await dispatch("TableBookingStore/process_booking_update", event.data, {
        root: true,
      });
      break;
    default:
      console.error(
        `[WS] Unhandled opration for bookings "${event.operation}"`
      );
      break;
  }
}

async function on_services_event(event, dispatch) {
  switch (event.operation) {
    case Operations.INSERT:
    case Operations.UPDATE:
      await dispatch("ShopStore/get_shop", event.shop_id, { root: true });
      break;
    default:
      console.error(
        `[WS] Unhandled opration for services "${event.operation}"`
      );
      break;
  }
}
async function on_stripe_connect_event(event, dispatch) {
  switch (event.operation) {
    case Operations.INSERT:
    case Operations.UPDATE:
      await dispatch("ShopStore/get_shop", event.shop_id, { root: true });
      break;
    default:
      console.error(
        `[WS] Unhandled opration for stripe_connects "${event.operation}"`
      );
      break;
  }
}

export default {
  namespaced: true,
  state: {
    status: undefined,
  },
  mutations: {
    SET_STATUS(state, status) {
      state.status = status;
    },
  },
  actions: {
    connect({ commit, dispatch, rootState }, shop_id) {
      try {
        commit("SET_STATUS", RealtimeStatus.CONNECTING);
        socket = new WebSocket(
          `${process.env.VUE_APP_WEBSOCKET_URL}/ws/${shop_id}`
        );
        commit("SET_STATUS", RealtimeStatus.CONNECTED);
        if (reconnect_interval) {
          clearInterval(reconnect_interval);
        }
        socket.onopen = () => onopen();
        socket.onmessage = (event) => onmessage(event, dispatch, rootState);
        socket.onclose = (event) => onclose(event, dispatch, commit, rootState);
        socket.onerror = (event) => console.error(event);
      } catch (error) {
        console.log("[WS] ", error);
      }
    },
    disconnect() {
      if (!socket) return;
      socket.close(4000);
    },
  },
};
