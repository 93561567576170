import { get_shop } from "@/requests";

export default {
  namespaced: true,
  state: {
    shop: null,
  },
  mutations: {
    SET_SHOP(state, shop) {
      state.shop = shop;
    },
  },
  actions: {
    async get_shop({ commit }, shop_id) {
      try {
        const { data: shop } = await get_shop(shop_id);
        commit("SET_SHOP", shop);
      } catch (e) {
        console.error(e);
      }
    },
  },
  getters: {
    table_booking(state) {
      return state.shop && state.shop.table_booking;
    },
  },
};
