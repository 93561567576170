<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    class="custom-icon"
  >
    <g id="Group_688" transform="translate(-308 -273)">
      <g id="Group_684" transform="translate(313.477 279)">
        <path
          id="Path_642"
          d="M124.373 215.38h-6.194c-.716 0-.863-.184-.762-.881a6.955 6.955 0 0 1 .6-2.009 3.483 3.483 0 0 1 3.221-2.046q3.028-.11 6.056-.009a3.715 3.715 0 0 1 3.909 3.111 10.213 10.213 0 0 1 .211 1.028c.1.587-.083.8-.661.8-2.132.006-4.253.006-6.38.006z"
          class="cls-2"
          transform="translate(-117.389 -202.379)"
        />
        <path
          id="Path_643"
          d="M167.5 126.579a3.94 3.94 0 0 1-.8 2.588 2.824 2.824 0 0 1-4.542.083 4.43 4.43 0 0 1-.266-4.909 3.089 3.089 0 0 1 3.294-1.147 2.817 2.817 0 0 1 2.211 2.358 4.634 4.634 0 0 1 .103 1.027z"
          class="cls-2"
          transform="translate(-157.247 -123.111)"
        />
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: "Account",
};
</script>
<style lang="scss" scoped>
.custom-icon {
  outline: none;
  g {
    path {
      fill: currentColor;
    }
  }
}
</style>
