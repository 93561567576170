<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.25"
    height="16"
    viewBox="0 0 17.25 16"
  >
    <g id="_6190425961595501135" transform="translate(-2.5 -3.5)">
      <path
        id="Line_16"
        d="M0 0L0 2"
        class="cls-1"
        transform="translate(8 7)"
      />
      <path
        id="Line_17"
        d="M0 0L0 2"
        class="cls-1"
        transform="translate(15 7)"
      />
      <path
        id="Line_18"
        d="M0 0L0 2"
        class="cls-1"
        transform="translate(12 6)"
      />
      <path
        id="Path_534"
        d="M18.95 10.25a7.476 7.476 0 0 0-14.75 0z"
        class="cls-1"
        transform="translate(-.45)"
      />
      <path
        id="Path_535"
        d="M4 16l.938.625a1.7 1.7 0 0 0 1.875 0 1.7 1.7 0 0 1 1.875 0 1.7 1.7 0 0 0 1.875 0 1.7 1.7 0 0 1 1.875 0 1.7 1.7 0 0 0 1.875 0 1.7 1.7 0 0 1 1.875 0 1.7 1.7 0 0 0 1.875 0L19 16"
        class="cls-1"
        transform="translate(-.375 -4.5)"
      />
      <path
        id="Path_536"
        d="M18 22.5H4.25A1.254 1.254 0 0 1 3 21.25 1.254 1.254 0 0 1 4.25 20H18a1.254 1.254 0 0 1 1.25 1.25A1.254 1.254 0 0 1 18 22.5z"
        class="cls-1"
        transform="translate(0 -6)"
      />
      <path
        id="Path_537"
        d="M18.75 24v.875a1.614 1.614 0 0 1-1.625 1.625h-10.5A1.655 1.655 0 0 1 5 24.875V24"
        class="cls-1"
        transform="translate(-.75 -7.5)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MyOrders",
};
</script>
<style scoped>
.cls-1 {
  fill: none;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>
