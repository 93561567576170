<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.309"
    height="17.953"
    viewBox="0 0 16.309 17.953"
  >
    <g id="_19097378441555590644" transform="translate(-19.535)">
      <path
        id="Path_557"
        d="M69.955 68.454L58.426 61.8l11.529-6.656.6 1.044-9.717 5.612 9.72 5.612z"
        class="cls-1 fillable"
        transform="translate(-37.255 -52.822)"
      />
      <circle
        id="Ellipse_49"
        cx="2.842"
        cy="2.842"
        r="2.842"
        class="cls-1 fillable"
        transform="translate(19.535 6.134)"
      />
      <circle
        id="Ellipse_50"
        cx="2.842"
        cy="2.842"
        r="2.842"
        class="cls-1 fillable"
        transform="translate(30.159)"
      />
      <circle
        id="Ellipse_51"
        cx="2.842"
        cy="2.842"
        r="2.842"
        class="cls-1 fillable"
        transform="translate(30.159 12.268)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Advertising",
};
</script>

<style>
.cls-1 {
  fill: #666;
}
</style>
