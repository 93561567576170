<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.318"
    height="13.054"
    viewBox="0 0 16.318 13.054"
  >
    <path
      d="M18.318 6.856C18.318 5.281 14.663 4 10.159 4S2 5.281 2 6.856c0 1.477 3.223 2.7 7.343 2.839v3.28H8A1.64 1.64 0 0 0 6.479 14l-1.215 3.054H6.9l.979-2.448h4.569l.979 2.448h1.632L13.83 14a1.629 1.629 0 0 0-1.509-1.028h-1.346V9.695c4.12-.139 7.343-1.363 7.343-2.839z"
      transform="translate(-2 -4)"
      class="cls-1"
    />
  </svg>
</template>

<script>
export default {
  name: "Table",
};
</script>
<style scoped>
.cls-1 {
  fill: #666;
  stroke: #666;
  stroke-width: 0.7px;
}
</style>
