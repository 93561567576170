<template>
  <v-dialog
    v-model="show"
    :class="{ hidden: !important_update }"
    persistent
    width="550px"
    class="br-16"
  >
    <v-card class="br-16">
      <v-card-title
        class="primary white--text text-h3 text-center font-weight-bold pb-4"
        >Important Update</v-card-title
      >
      <v-card-text>
        <v-row class="pb-0">
          <v-col cols="2" class="d-flex align-center justify-center">
            <v-icon large>mdi-autorenew</v-icon>
          </v-col>
          <v-col cols="10">
            <p class="mb-0">{{ text }}</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-form
        class="d-flex justify-center pb-8"
        method="get"
        action="/"
        ref="reload-form"
      >
        <v-btn
          id="reload-submit"
          color="primary"
          type="submit"
          rounded
          class="mr-0"
          @click.prevent="handle_reload"
          ><span class="no-text-transform">Reload App Now</span></v-btn
        >
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { db } from "@/db";

export default {
  name: "ImportantUpdate",
  computed: {
    ...mapGetters("AdminStore", ["important_update"]),
    ...mapState("AdminStore", ["shop_id"]),
    text() {
      return this.important_update?.text || "";
    },
    show: {
      get() {
        return this.important_update;
      },
      set() {},
    },
  },
  methods: {
    async dismiss_notification() {
      await db
        .collection("shops")
        .doc(this.shop_id)
        .collection("notifications")
        .doc(this.important_update.id)
        .update({
          seen: true,
        });
    },
    async handle_reload() {
      await this.dismiss_notification();
      this.$refs["reload-form"].$el.submit();
    },
  },
};
</script>
