import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from "vuexfire";

import AdminStore from "@/store/AdminStore";
import UserStore from "@/store/UserStore";
import BrandStore from "@/store/BrandStore";
import ErrorStore from "@/store/ErrorStore";
import StaffStore from "@/store/StaffStore";
import TableBookingStore from "@/store/TableBookingStore";
import ShopStore from "@/store/ShopStore";
import RealtimeStore from "@/store/RealtimeStore";
import SuperAdminStore from "@/store/SuperAdminStore";
import FileExplorerStore from "@/store/FileExplorerStore";
import ReviewStore from "@/store/ReviewStore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    barColor: "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    drawer: false,
    server_url: process.env.VUE_APP_EXPRESS_SERVER_URL + "/api",
    iframe_server_url: process.env.VUE_APP_EXPRESS_SERVER_URL + "/iframe",
    api_server_url: process.env.VUE_APP_SERVER_API + "/api/v1",
  },
  mutations: {
    ...vuexfireMutations,
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },
  },
  modules: {
    ShopStore,
    AdminStore,
    UserStore,
    BrandStore,
    ErrorStore,
    StaffStore,
    TableBookingStore,
    RealtimeStore,
    SuperAdminStore,
    FileExplorerStore,
    ReviewStore,
  },
});
