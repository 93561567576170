import Vue from "vue";
import { firestorePlugin } from "vuefire";

import firebase from "firebase/app";
import "firebase/firestore";

Vue.use(firestorePlugin);

export const firebase_app = firebase.initializeApp(
  JSON.parse(process.env.VUE_APP_FIREBASE)
);
export const db = firebase_app.firestore();
export const firestore = firebase.firestore;
