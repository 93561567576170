<template>
  <v-dialog
    v-model="dialog"
    :width="width"
    content-class="br-16"
    :fullscreen="fullscreen"
    scrollable
  >
    <v-card class="action-modal" style="max-height: 700px">
      <v-card-title
        v-if="header"
        class="text-left primary white--text font-weight-bold pb-4"
      >
        {{ header }}
      </v-card-title>

      <v-card-text class="action-modal-content py-8">
        <slot name="content"> </slot>
      </v-card-text>
      <v-divider />
      <v-card-actions class="justify-end">
        <v-btn
          v-if="$listeners.delete"
          :disabled="disabled_delete"
          color="red"
          rounded
          text
          :loading="loading_id == 'delete' && loading"
          @click="$emit('delete')"
        >
          {{ delete_text }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="dark-grey"
          rounded
          text
          @click="
            $emit('close');
            dialog = false;
          "
        >
          {{ close_text }}
        </v-btn>
        <v-btn
          :color="confirm_color"
          :disabled="disabled"
          :loading="loading_id == 'confirm' && loading"
          rounded
          text
          @click="$emit('confirm')"
        >
          {{ confirm_text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AdminDialog",
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 500,
    },
    header: {
      type: String,
      default: "Are you sure?",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabled_delete: {
      type: Boolean,
      default: false,
    },
    close_text: {
      type: String,
      default: "Cancel",
    },
    confirm_text: {
      type: String,
      default: `Confirm`,
    },
    confirm_color: {
      type: String,
      default: `primary`,
    },
    delete_text: {
      type: String,
      default: "Delete",
    },
    loading_id: {
      type: String,
      default: "confirm",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.show_dialog;
      },
      set(value) {
        this.$emit("update:show_dialog", value);
      },
    },
  },
};
</script>
