<template>
  <svg
    class="custom-icon"
    width="27.057"
    height="25.989"
    viewBox="0 0 27.057 25.989"
  >
    <g>
      <path
        stroke-width="0.3px"
        d="M14.936.869A.978.978 0 0 1 16 0a.977.977 0 0 1 1.06.869v3.8a.978.978 0 0 1-1.06.869.977.977 0 0 1-1.06-.869v-3.8zM2.884 12.3c-.059 0-.114-.263-.114-.587s.048-.585.114-.585h2.89c.059 0 .114.263.114.585s-.048.587-.114.587zm4.607 0c-.059 0-.114-.263-.114-.587s.048-.585.114-.585h2.89c.059 0 .114.263.114.585s-.048.587-.114.587zm4.607 0c-.059 0-.114-.263-.114-.587s.046-.585.114-.585h2.89c.059 0 .114.261.114.582a8.818 8.818 0 0 0-.834.589zM2.89 15.661c-.059 0-.114-.263-.114-.587s.048-.587.114-.587h2.89c.059 0 .114.263.114.587s-.048.587-.114.587zm4.607 0c-.059 0-.114-.263-.114-.587s.048-.587.114-.587h2.89c.059 0 .114.263.114.587s-.048.587-.114.587zM2.9 19.024c-.059 0-.114-.263-.114-.587s.048-.587.114-.587h2.89c.059 0 .114.263.114.587s-.048.587-.114.587zm4.607 0c-.059 0-.114-.263-.114-.587s.048-.587.114-.587h2.89c.059 0 .114.263.114.587s-.048.587-.114.587zM5.422.869A.978.978 0 0 1 6.482 0a.977.977 0 0 1 1.06.869v3.8a.98.98 0 0 1-1.06.869.977.977 0 0 1-1.06-.869v-3.8zM1.167 8.306h20.172V3.933a.533.533 0 0 0-.155-.377.539.539 0 0 0-.377-.155h-1.934a.587.587 0 0 1 0-1.174h1.934a1.712 1.712 0 0 1 1.71 1.71v6.944a8.641 8.641 0 0 0-1.189-.32V9.477H1.167V20.8a.524.524 0 0 0 .155.377.539.539 0 0 0 .377.155h9.587a8.835 8.835 0 0 0 .412 1.187H1.71A1.7 1.7 0 0 1 .5 22.02a1.69 1.69 0 0 1-.5-1.207V3.937a1.712 1.712 0 0 1 1.71-1.71h2.065a.587.587 0 0 1 0 1.174H1.71a.524.524 0 0 0-.377.155.539.539 0 0 0-.155.377v4.373zM9.234 3.4a.587.587 0 1 1 0-1.174h3.937a.587.587 0 0 1 0 1.174z"
        transform="translate(.15 .15)"
      />
      <path
        fill-rule="evenodd"
        d="M67.248 55.53a6.836 6.836 0 0 1 4.837 2 6.887 6.887 0 0 1 1.485 2.22 6.839 6.839 0 0 1-1.485 7.452 6.91 6.91 0 0 1-2.22 1.485 6.841 6.841 0 0 1-7.452-11.159 6.812 6.812 0 0 1 4.835-2zm-.714 4.182a.806.806 0 0 1 .061-.307.846.846 0 0 1 .173-.261.754.754 0 0 1 .261-.173.8.8 0 0 1 .613 0 .8.8 0 0 1 .261.173.786.786 0 0 1 .173.261.8.8 0 0 1 .061.307v2.878l1.813.959.046.028a.8.8 0 0 1 .206.2.776.776 0 0 1 .125.269.847.847 0 0 1 .018.307.768.768 0 0 1-.1.289l-.02.033-.015.02a.8.8 0 0 1-.46.322.847.847 0 0 1-.307.018.769.769 0 0 1-.289-.1l-2.2-1.176a.68.68 0 0 1-.169-.123.811.811 0 0 1-.134-.164v-.009a.779.779 0 0 1-.116-.409v-3.342z"
        transform="translate(.15 .15) translate(-47.182 -43.371)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Calendar",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  g {
    fill: currentColor;

    path:nth-last-of-type(0) {
      stroke: currentColor;
    }
    path:nth-last-of-type(1) {
      fill-rule: evenodd;
    }
  }
}
</style>
