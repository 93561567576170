<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    class="custom-icon"
  >
    <g transform="translate(-381 -303)">
      <path
        fill-rule="evenodd"
        d="M13.362 4h-10.1a1.261 1.261 0 0 0-1.256 1.262L2 12.837A1.266 1.266 0 0 0 3.262 14.1h10.1a1.266 1.266 0 0 0 1.262-1.262V5.262A1.266 1.266 0 0 0 13.362 4zm-.252 2.683l-4.463 2.79a.638.638 0 0 1-.669 0l-4.463-2.79a.536.536 0 1 1 .568-.909l4.229 2.644 4.229-2.645a.536.536 0 1 1 .568.909z"
        transform="translate(385.092 306.354)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Mail",
};
</script>
<style lang="scss" scoped>
.custom-icon {
  outline: none;
  g {
    path {
      fill: currentColor;
    }
  }
}
</style>
