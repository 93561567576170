<template>
  <v-app>
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <ErrorDialog />
    <ImportantUpdate />
  </v-app>
</template>

<script>
import "@/sass/theme.scss";
import ErrorDialog from "@/components/base/ErrorDialog";
import ImportantUpdate from "@/views/dashboard/components/core/ImportantUpdate";

export default {
  name: "App",
  components: {
    ErrorDialog,
    ImportantUpdate,
  },
};
</script>
