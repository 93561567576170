<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.498"
    height="20.65"
    class="custom-icon"
  >
    <g data-name="6146048471608673000">
      <path
        data-name="Path 580"
        d="M11.85.69a.776.776 0 0 1 .841-.69.775.775 0 0 1 .841.69V3.7a.776.776 0 0 1-.841.69.775.775 0 0 1-.841-.69V.69zM2.288 9.757c-.047 0-.09-.208-.09-.466s.038-.464.09-.464h2.293c.047 0 .09.208.09.464s-.038.466-.09.466zm3.655 0c-.047 0-.09-.208-.09-.466s.038-.464.09-.464h2.293c.047 0 .09.208.09.464s-.038.466-.09.466zm3.655 0c-.047 0-.09-.208-.09-.466s.036-.464.09-.464h2.293c.047 0 .09.207.09.462a7 7 0 0 0-.662.467zm-7.305 2.668c-.047 0-.09-.208-.09-.466s.038-.466.09-.466h2.293c.047 0 .09.208.09.466s-.038.466-.09.466zm3.655 0c-.047 0-.09-.208-.09-.466s.038-.466.09-.466h2.294c.047 0 .09.208.09.466s-.038.466-.09.466zM2.3 15.094c-.047 0-.09-.208-.09-.466s.038-.466.09-.466h2.292c.047 0 .09.208.09.466s-.038.466-.09.466zm3.655 0c-.047 0-.09-.208-.09-.466s.038-.466.09-.466h2.292c.047 0 .09.208.09.466s-.038.466-.09.466zM4.3.69A.776.776 0 0 1 5.142 0a.775.775 0 0 1 .841.69V3.7a.777.777 0 0 1-.841.69.775.775 0 0 1-.842-.69V.69zM.926 6.59h16V3.12a.423.423 0 0 0-.123-.3.428.428 0 0 0-.3-.123h-1.529a.466.466 0 0 1 0-.931h1.534a1.358 1.358 0 0 1 1.357 1.357v5.51a6.856 6.856 0 0 0-.943-.254v-.86h-16V16.5a.415.415 0 0 0 .123.3.428.428 0 0 0 .3.123h7.609a7.009 7.009 0 0 0 .327.942H1.357a1.351 1.351 0 0 1-.959-.4 1.341 1.341 0 0 1-.4-.957V3.124a1.358 1.358 0 0 1 1.359-1.357H3A.466.466 0 0 1 3 2.7H1.357a.415.415 0 0 0-.3.123.428.428 0 0 0-.123.3V6.59zm6.4-3.89a.466.466 0 1 1 0-.931h3.124a.466.466 0 1 1 0 .931z"
        transform="translate(.15 .15)"
      />
      <path
        data-name="Path 581"
        d="M65.836 55.53a5.424 5.424 0 0 1 3.838 1.59 5.464 5.464 0 0 1 1.178 1.762 5.426 5.426 0 0 1-1.178 5.912 5.483 5.483 0 0 1-1.762 1.178A5.427 5.427 0 0 1 62 57.118a5.4 5.4 0 0 1 3.836-1.588zm-.566 3.318a.64.64 0 0 1 .049-.243.671.671 0 0 1 .137-.207.6.6 0 0 1 .207-.137.632.632 0 0 1 .486 0 .633.633 0 0 1 .207.137.623.623 0 0 1 .137.207.633.633 0 0 1 .049.243v2.283l1.438.761.036.023a.638.638 0 0 1 .163.156.615.615 0 0 1 .1.214.672.672 0 0 1 .014.243.61.61 0 0 1-.078.229l-.016.026-.012.016a.633.633 0 0 1-.365.255.672.672 0 0 1-.243.014.61.61 0 0 1-.229-.078l-1.75-.933a.54.54 0 0 1-.134-.1.643.643 0 0 1-.106-.13v-.007a.618.618 0 0 1-.092-.325v-2.647z"
        transform="translate(-49.765 -45.733)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "CalendarClock",
};
</script>
<style lang="scss" scoped>
.custom-icon {
  outline: none;
  g {
    path {
      fill: currentColor;
      stroke: currentColor;
      stroke-width: 0.3px;
      &:last-of-type {
        fill-rule: evenodd;
      }
    }
  }
}
</style>
