import Vue from "vue";
import Router from "vue-router";
import routes from "@/router/routes";
import VueGtag from "vue-gtag";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

if (
  process.env.NODE_ENV === "production" &&
  process.env.VUE_APP_ANALYTICS_CODE
) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_ANALYTICS_CODE },
    },
    router
  );
}

export default router;
