export default {
  namespaced: true,
  state: {
    brand: null,
  },
  mutations: {
    SET_BRAND(state, { brand = process.env.VUE_APP_BRAND }) {
      state.brand = brand;
    },
  },
  actions: {
    set_brand(context, brand) {
      context.commit("SET_BRAND", { brand });
    },
  },
  getters: {
    brand_type: (state) => state.brand?.type ?? undefined,
    brand_url: (state) => state.brand?.url,
    lowercase_brand_name: (state) =>
      state?.brand?.name?.toLowerCase() ?? "business-name",
    brand_contact: (state) => state.brand?.contact ?? undefined,
  },
};
