import {
  get_brands,
  get_shops,
  get_brand_by_id,
  add_brand_zone,
  update_brand_zone,
  delete_brand_zone,
  associate_shop_with_brand_zone,
  dissociate_shop_from_brand_zone,
  upsert_blog_post,
  delete_blog_post,
  change_custom_page_order,
} from "@/requests";

export default {
  namespaced: true,
  state: {
    shops: [],
    shops_filters: {
      autocomplete: "",
      brand_id: [],
    },
    loading_shops: false,
    brands: [],
    selected_brand: null,
    selected_brand_shops: [],
    custom_page_datatable_loading: false,
    loading_custom_page_id: null,
  },
  mutations: {
    SET_SHOPS(state, shops) {
      state.shops = shops;
    },
    SET_LOADING_SHOPS(state, loading_shops) {
      state.loading_shops = loading_shops;
    },
    SET_BRANDS(state, brands) {
      state.brands = brands;
    },
    SET_SELECTED_BRAND(state, brand) {
      state.selected_brand = brand;
    },
    SET_SELECTED_BRAND_SHOPS(state, shops) {
      state.selected_brand_shops = shops;
    },
    SET_CUSTOM_PAGES(state, pages) {
      state.selected_brand.custom_pages = pages;
    },
    SET_LOADING_CUSTOM_PAGE_ID(state, page_id) {
      state.loading_custom_page_id = page_id;
    },
    SET_CUSTOM_PAGE_DATATABLE_LOADING(state, loading) {
      state.custom_page_datatable_loading = loading;
    },
    CLEAR(state) {
      state.shops = [];
      state.brands = [];
      state.selected_brand = null;
      state.selected_brand_shops = [];
    },
    SET_SHOPS_FILTERS(state, filter) {
      state.shops_filters = Object.assign(state.shops_filters, filter);
    },
  },
  actions: {
    clear_super_admin({ commit }) {
      commit("CLEAR");
    },
    async get_shops({ commit }, brand_id) {
      commit("SET_LOADING_SHOPS", true);
      const { data: shops } = await get_shops({ brand_id });
      commit("SET_SHOPS", shops);
      commit("SET_LOADING_SHOPS", false);
    },
    async get_brand_shops(context, brand_id) {
      const { data: shops } = await get_shops({ brand_id });
      context.commit("SET_SELECTED_BRAND_SHOPS", shops);
    },
    async get_brands(context) {
      const { data: brands } = await get_brands();
      context.commit("SET_BRANDS", brands);
    },
    set_selected_brand({ commit }, brand) {
      commit("SET_SELECTED_BRAND", brand);
    },
    async get_brand_by_id(context, brand_id) {
      const { data: brand } = await get_brand_by_id(brand_id);
      context.commit("SET_SELECTED_BRAND", brand);
    },
    async add_brand_zone(context, { params }) {
      await add_brand_zone(params);
      context.dispatch("get_brand_by_id", context.state.selected_brand.id);
    },
    async update_brand_zone(context, { brand_zone_id, params }) {
      await update_brand_zone(brand_zone_id, params);
      context.dispatch("get_brand_by_id", context.state.selected_brand.id);
    },
    async delete_brand_zone(context, { brand_zone_id }) {
      await delete_brand_zone(brand_zone_id);
      context.dispatch("get_brand_by_id", context.state.selected_brand.id);
    },
    async associate_shop_with_brand_zone(context, { brand_zone_id, shop_id }) {
      await associate_shop_with_brand_zone(brand_zone_id, shop_id);
      context.dispatch("get_brand_by_id", context.state.selected_brand.id);
    },
    async dissociate_shop_from_brand_zone(context, { brand_zone_id, shop_id }) {
      await dissociate_shop_from_brand_zone(brand_zone_id, shop_id);
      context.dispatch("get_brand_by_id", context.state.selected_brand.id);
    },
    async upsert_custom_page({ dispatch }, { page }) {
      if (Object.keys(page).includes("id")) {
        dispatch("update_custom_page", { page_id: page.id, updates: page });
      } else {
        const { data: new_page } = await upsert_blog_post(page);
        dispatch("append_custom_page_to_brand", { page: new_page });
      }
    },
    async update_custom_page({ state, commit }, { page_id, updates }) {
      const page_idx = state.selected_brand.custom_pages.findIndex(
        (p) => p.id === page_id
      );
      if (page_idx === -1) return;
      commit("SET_LOADING_CUSTOM_PAGE_ID", page_id);
      try {
        const { data: updated_page } = await upsert_blog_post({
          ...state.selected_brand.custom_pages[page_idx],
          ...updates,
        });
        let new_pages = [...state.selected_brand.custom_pages];
        new_pages[page_idx] = updated_page;
        commit("SET_CUSTOM_PAGES", new_pages);
      } catch (e) {
        console.error(e);
      } finally {
        commit("SET_LOADING_CUSTOM_PAGE_ID", null);
      }
    },
    async delete_custom_page({ state, commit }, { page_id }) {
      const page_idx = state.selected_brand.custom_pages.findIndex(
        (p) => p.id === page_id
      );
      if (page_idx === -1) return;
      commit("SET_LOADING_CUSTOM_PAGE_ID", page_id);
      try {
        await delete_blog_post(page_id);
        let new_pages = [...state.selected_brand.custom_pages];
        const deleted_order = new_pages[page_idx].order;
        new_pages.splice(page_idx, 1);
        new_pages.forEach((p) => {
          if (p.order > deleted_order) {
            p.order--;
          }
        });
        commit("SET_CUSTOM_PAGES", new_pages);
      } catch (e) {
        console.error(e);
      } finally {
        commit("SET_LOADING_CUSTOM_PAGE_ID", null);
      }
    },
    async change_custom_page_order({ state, commit }, { page_id, direction }) {
      const order_change = direction == "up" ? 1 : -1;
      const page_idx = state.selected_brand.custom_pages.findIndex(
        (p) => p.id === page_id
      );
      if (
        page_idx + order_change < 0 ||
        page_idx + order_change > state.selected_brand.custom_pages.length - 1
      )
        return;
      commit("SET_CUSTOM_PAGE_DATATABLE_LOADING", true);
      try {
        await change_custom_page_order(page_id, direction);
        let new_pages = [...state.selected_brand.custom_pages];
        new_pages[page_idx].order += order_change;
        new_pages[page_idx + order_change].order -= order_change;
        new_pages.sort((p_a, p_b) => {
          return p_a.order - p_b.order;
        });
        commit("SET_CUSTOM_PAGES", new_pages);
      } catch (e) {
        console.error(e);
      } finally {
        commit("SET_CUSTOM_PAGE_DATATABLE_LOADING", false);
      }
    },
    append_custom_page_to_brand({ state, commit }, { page }) {
      const new_pages = [...state.selected_brand.custom_pages, page];
      commit("SET_CUSTOM_PAGES", new_pages);
    },
    set_shops_filter({ commit }, filter) {
      commit("SET_SHOPS_FILTERS", filter);
    },
  },
  getters: {
    filtered_shops: (state) => {
      const filters = state.shops_filters;

      const are_filters_empty = Object.values(filters).every(
        (value) => value == false
      );
      if (are_filters_empty) return state.shops;

      return state.shops.filter((shop) => {
        const conditions = [];

        if (filters.autocomplete) {
          const name = shop.name.toLowerCase();
          const autocomplete = filters.autocomplete.toLowerCase();

          const name_match = name.includes(autocomplete);
          conditions.unshift(name_match);
        }
        if (filters.brand_id.length) {
          const brand_ids = filters.brand_id;

          const brand_match = shop.brands.some((brand) =>
            brand_ids.includes(brand.id)
          );
          conditions.unshift(brand_match);
        }

        return conditions.every((condition) => condition === true);
      });
    },
  },
};
