import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@/sass/overrides.sass";

import Advertising from "@/components/icons/Advertising.vue";
import Bell from "@/components/icons/Bell.vue";
import Deliveries from "@/components/icons/Deliveries.vue";
import DeliveryPlanner from "@/components/icons/DeliveryPlanner.vue";
import Help from "@/components/icons/Help.vue";
import Logout from "@/components/icons/Logout.vue";
import MyOrders from "@/components/icons/MyOrders.vue";
import Products from "@/components/icons/Products.vue";
import Retail from "@/components/icons/Retail.vue";
import RetailOrders from "@/components/icons/RetailOrders.vue";
import SettingsCog from "@/components/icons/SettingsCog.vue";
import Vouchers from "@/components/icons/Vouchers.vue";
import ProductsList from "@/components/icons/ProductsList.vue";
import Categories from "@/components/icons/Categories.vue";
import Collection from "@/components/icons/Collection.vue";
import Chat from "@/components/icons/Chat.vue";
import Delete from "@/components/icons/Delete.vue";
import Edit from "@/components/icons/Edit.vue";
import Copy from "@/components/icons/Copy.vue";
import ClockAdd from "@/components/icons/ClockAdd.vue";
import ClockDone from "@/components/icons/ClockDone.vue";
import Calendar from "@/components/icons/Calendar.vue";
import SandClock from "@/components/icons/SandClock.vue";
import ClockElapsed from "@/components/icons/ClockElapsed.vue";
import Drink from "@/components/icons/Drink.vue";
import MapPin from "@/components/icons/MapPin.vue";
import Reports from "@/components/icons/Reports.vue";
import Notes from "@/components/icons/Notes.vue";
import CalendarClock from "@/components/icons/CalendarClock.vue";
import Phone from "@/components/icons/Phone.vue";
import Account from "@/components/icons/Account.vue";
import Mail from "@/components/icons/Mail.vue";
import CheckMark from "@/components/icons/CheckMark.vue";
import CommunityPin from "@/components/icons/CommunityPin.vue";
import Table from "@/components/icons/Table.vue";
import Seated from "@/components/icons/Seated.vue";
import HandMoney from "@/components/icons/HandMoney.vue";
import NoteCircle from "@/components/icons/NoteCircle.vue";

Vue.use(Vuetify);

const light_theme = {
  primary: "#54c4c9",
  secondary: "#fff",
  foodie: "#46c2db",
  accent: "#ff8080",
  info: "#00CAE3",
  "dark-grey": "#666666",
  "light-grey": "#f7f7f7",

  yellow: "#FFEC67",
  new: "#ff8080",
  processing: "#7acb9f",
  delivery_planned: "#a582c7",
  planned: "#a582c7",
  ready: "#54c4c9",
  delivery_returned: "#666666",
  completed: "#c9c9c9",
};

export default new Vuetify({
  theme: {
    light: true,
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      dark: light_theme,
      light: light_theme,
    },
  },
  icons: {
    values: {
      sort: "mdi-chevron-down",
      dropdown: "mdi-chevron-down",
      advertising: {
        component: Advertising,
      },
      bell: {
        component: Bell,
      },
      chat: {
        component: Chat,
      },
      deliveries: {
        component: Deliveries,
      },
      delivery: {
        component: Deliveries,
      },
      hospitality_delivery: {
        component: Deliveries,
      },
      collection: {
        component: Collection,
      },
      takeaway: {
        component: Collection,
      },
      "delivery-planner": {
        component: DeliveryPlanner,
      },
      help: {
        component: Help,
      },
      logout: {
        component: Logout,
      },
      "my-orders": {
        component: MyOrders,
      },
      products: {
        component: Products,
      },
      retail: {
        component: Retail,
      },
      "retail-orders": {
        component: RetailOrders,
      },
      "settings-cog": {
        component: SettingsCog,
      },
      vouchers: {
        component: Vouchers,
      },
      "products-list": {
        component: ProductsList,
      },
      categories: {
        component: Categories,
      },
      delete: {
        component: Delete,
      },
      edit: {
        component: Edit,
      },
      copy: {
        component: Copy,
      },
      "clock-add": {
        component: ClockAdd,
      },
      "clock-done": {
        component: ClockDone,
      },
      calendar: {
        component: Calendar,
      },
      "sand-clock": {
        component: SandClock,
      },
      "clock-elapsed": {
        component: ClockElapsed,
      },
      drink: {
        component: Drink,
      },
      "map-pin": {
        component: MapPin,
      },
      reports: {
        component: Reports,
      },
      notes: {
        component: Notes,
      },
      "calendar-clock": {
        component: CalendarClock,
      },
      phone: {
        component: Phone,
      },
      account: {
        component: Account,
      },
      mail: {
        component: Mail,
      },
      check: {
        component: CheckMark,
      },
      "community-pin": {
        component: CommunityPin,
      },
      table: {
        component: Table,
      },
      seated: {
        component: Seated,
      },
      "hand-money": {
        component: HandMoney,
      },
      "note-circle": {
        component: NoteCircle,
      },
    },
  },
});
