<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="17.558"
    height="24.739"
    viewBox="0 0 17.558 24.739"
  >
    <g id="_18159369621571183082" transform="translate(-6.3 -2.3)">
      <path
        id="Path_562"
        d="M20.465 5.693H9.693A2.645 2.645 0 0 1 7 3h16.158a2.645 2.645 0 0 1-2.693 2.693z"
        class="cls-1"
      />
      <path
        id="Path_563"
        d="M9.693 26h10.772a2.645 2.645 0 0 1 2.693 2.693H7A2.645 2.645 0 0 1 9.693 26z"
        class="cls-1"
        transform="translate(0 -2.354)"
      />
      <path
        id="Path_564"
        d="M22.455 23.953v-3.59a1.874 1.874 0 0 0-.718-1.436l-3.321-2.513a1.763 1.763 0 0 1 0-2.873l3.321-2.513a1.874 1.874 0 0 0 .718-1.436V6"
        class="cls-1"
        transform="translate(-1.092 -.307)"
      />
      <path
        id="Path_565"
        d="M9 6v3.591a1.874 1.874 0 0 0 .718 1.436l3.322 2.513a1.763 1.763 0 0 1 0 2.873l-3.322 2.513A1.874 1.874 0 0 0 9 20.363v3.591"
        class="cls-1"
        transform="translate(-.205 -.307)"
      />
      <path
        id="Path_566"
        d="M11 25.488L15.488 21l4.488 4.488z"
        class="cls-1"
        transform="translate(-.409 -1.842)"
      />
      <path
        id="Path_567"
        d="M15.693 11.8L13 10h5.386z"
        class="cls-1"
        transform="translate(-.614 -.716)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "SandClock",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  .cls-1 {
    fill: none;
    stroke: currentColor;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 1.4px;
  }
}
</style>
