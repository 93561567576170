export default {
  namespaced: true,
  state: {
    error: null,
    ignore_before_route_leave_guard: null,
  },
  mutations: {
    SET_ERROR(state, error) {
      state.error = error;
    },
    TOGGLE_IGNORE_BEFORE_ROUTE_LEAVE_GUARD(state) {
      state.ignore_before_route_leave_guard =
        !state.ignore_before_route_leave_guard;
    },
  },
  actions: {
    set_error(context, { error }) {
      context.commit("SET_ERROR", error);
    },
    toggle_ignore_before_route_leave_guard({ commit }) {
      commit("TOGGLE_IGNORE_BEFORE_ROUTE_LEAVE_GUARD");
    },
  },
};
