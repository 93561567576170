import { get_images, upload_image, delete_images } from "../requests";

import { cloneDeep } from "lodash";

export default {
  namespaced: true,
  state: {
    images: [],
    current_path: [],
    show_image_uploader: false,
    show_image_preview: false,
    is_fetching_images: false,
    is_sending_images: false,
    highlighted_directory: null,
    highlighted_image: null,
    highlighted_temporary_directory: null,
    selected_images: [],
    image_to_upload: null,
    current_temporary_path: [],
    temporary_directory_structure: {},
  },
  mutations: {
    SET_IMAGES(state, images) {
      state.images = images;
    },
    ADD_NEW_IMAGE(state, image) {
      state.images = [...state.images, image];
    },
    SET_SHOW_IMAGE_UPLOADER(state, show) {
      state.show_image_uploader = show;
    },
    SET_SHOW_IMAGE_PREVIEW(state, show) {
      state.show_image_preview = show;
    },
    SET_IS_FETCHING_IMAGES(state, is_fetching) {
      state.is_fetching_images = is_fetching;
    },
    SET_IS_SENDING_IMAGES(state, is_sending) {
      state.is_sending_images = is_sending;
    },
    SET_SELECTED_IMAGES(state, images) {
      state.selected_images = images;
      state.show_image_preview = !!state.selected_images.length;
    },
    REMOVE_SELECTED_IMAGE(state, index) {
      state.selected_images.splice(index, 1);
      state.show_image_preview = !!state.selected_images.length;
    },
    SET_IMAGE_TO_UPLOAD(state, image_data) {
      state.image_to_upload = image_data;
    },
    SET_CURRENT_PATH(state, path) {
      state.current_path = path;
    },
    SET_CURRENT_TEMPORARY_PATH(state, path) {
      state.current_temporary_path = path;
    },
    HIGHLIGHT_DIRECTORY(state, directory) {
      state.highlighted_image = null;
      state.highlighted_directory = directory;
      state.highlighted_temporary_directory = null;
    },
    HIGHLIGHT_IMAGE(state, image) {
      state.highlighted_directory = null;
      state.highlighted_image = image;
      state.highlighted_temporary_directory = null;
      state.show_image_preview = image != null;
    },
    HIGHLIGHT_TEMPORARY_DIRECTORY(state, directory) {
      state.highlighted_temporary_directory = directory;
      state.highlighted_image = null;
      state.highlighted_directory = null;
    },
    REMOVE_CURRENT_DIRECTORY_FROM_PATH(state) {
      state.current_path.pop();
    },
    REMOVE_CURRENT_TEMP_DIRECTORY_FROM_PATH(state) {
      state.current_temporary_path.pop();
    },
    ADD_TEMPORARY_DIRECTORY(state, directory_name) {
      const parent_path = state.current_path.length
        ? state.current_path.join("/")
        : "~";
      let structure_clone = cloneDeep(state.temporary_directory_structure);
      let ref = (structure_clone[parent_path] =
        structure_clone[parent_path] || {});
      state.current_temporary_path.forEach((dir) => {
        ref = ref[dir] = ref[dir] || {};
      });
      ref[directory_name] = {};
      state.temporary_directory_structure = structure_clone;
    },
    RESET_TEMPORARY_STRUCTURE(state) {
      state.temporary_directory_structure = {};
    },
    RESET_TEMPORARY_PATH(state) {
      state.current_temporary_path = [];
    },
  },
  actions: {
    async get_images({ commit }, params) {
      commit("SET_IS_FETCHING_IMAGES", true);
      try {
        const { data: images } = await get_images(params);
        commit("SET_IMAGES", images);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_FETCHING_IMAGES", false);
      }
    },
    async upload_image({ commit }, { shop_id, brand_id, file_path, file }) {
      try {
        commit("SET_IS_SENDING_IMAGES", true);
        const form_data = new FormData();
        form_data.append("shop_id", shop_id);
        form_data.append("brand_id", brand_id);
        form_data.append("file", file);
        form_data.append("file_path", file_path);
        const { data: image } = await upload_image(form_data);
        commit("ADD_NEW_IMAGE", image);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_SENDING_IMAGES", false);
      }
    },
    async delete_images({ commit, state }, { image_ids }) {
      try {
        commit("SET_IS_SENDING_IMAGES", true);
        await delete_images({ image_ids });
        const new_images = state.images.filter(
          (image) => !image_ids.includes(image.id)
        );
        commit("SET_IMAGES", new_images);
      } catch (error) {
        console.error(error);
      } finally {
        commit("SET_IS_SENDING_IMAGES", false);
      }
    },
    toggle_show_image_uploader({ commit, state }) {
      commit("SET_SHOW_IMAGE_UPLOADER", !state.show_image_uploader);
    },
    close_image_preview({ commit }) {
      commit("SET_SHOW_IMAGE_PREVIEW", false);
    },
    toggle_image_preview({ commit, state }) {
      commit("SET_SHOW_IMAGE_PREVIEW", !state.show_image_preview);
    },
    open_directory({ commit, state }, directory) {
      commit("SET_CURRENT_PATH", [...state.current_path, directory]);
      commit("HIGHLIGHT_DIRECTORY", null);
      commit("HIGHLIGHT_IMAGE", null);
    },
    set_path({ commit }, path) {
      commit("SET_CURRENT_PATH", path);
    },
    return_to_previous_directory({ commit, state }) {
      if (state.current_path.length) {
        commit("REMOVE_CURRENT_DIRECTORY_FROM_PATH");
      }
    },
    return_to_previous_temp_directory({ commit, state }) {
      if (state.current_temporary_path.length) {
        commit("REMOVE_CURRENT_TEMP_DIRECTORY_FROM_PATH");
      }
    },
    highlight_directory({ commit }, directory) {
      commit("HIGHLIGHT_DIRECTORY", directory);
    },
    highlight_image({ commit }, image) {
      commit("HIGHLIGHT_IMAGE", image);
    },
    highlight_temporary_directory({ commit }, directory) {
      commit("HIGHLIGHT_TEMPORARY_DIRECTORY", directory);
    },
    select_image({ commit, state, getters }, image) {
      if (!getters.selected_image_ids.includes(image.id)) {
        commit("SET_SELECTED_IMAGES", [...state.selected_images, image]);
      }
    },
    deselect_image({ commit, getters }, image) {
      const image_index = getters.selected_image_ids.indexOf(image.id);
      commit("REMOVE_SELECTED_IMAGE", image_index);
    },
    clear_selected_images({ commit }) {
      commit("SET_SELECTED_IMAGES", []);
    },
    set_image_to_upload({ commit }, image_data) {
      commit("SET_IMAGE_TO_UPLOAD", image_data);
    },
    reset_path({ commit }) {
      commit("SET_CURRENT_PATH", []);
      commit("SET_CURRENT_TEMPORARY_PATH", []);
    },
    navigate_to_breadcrumb({ commit, state }, breadcrumb) {
      if (!state.current_path.includes(breadcrumb)) return;
      const breadcrumb_index = state.current_path.indexOf(breadcrumb);
      commit("SET_CURRENT_TEMPORARY_PATH", []);
      commit(
        "SET_CURRENT_PATH",
        state.current_path.slice(0, breadcrumb_index + 1)
      );
    },
    navigate_to_temp_breadcrumb({ commit, state }, breadcrumb) {
      if (!state.current_temporary_path.includes(breadcrumb)) return;
      const breadcrumb_index = state.current_temporary_path.indexOf(breadcrumb);
      commit(
        "SET_CURRENT_TEMPORARY_PATH",
        state.current_temporary_path.slice(0, breadcrumb_index + 1)
      );
    },
    create_temporary_directory({ commit }, directory_name) {
      commit("ADD_TEMPORARY_DIRECTORY", directory_name);
    },
    open_temporary_directory({ commit, state }, directory) {
      commit("SET_CURRENT_TEMPORARY_PATH", [
        ...state.current_temporary_path,
        directory,
      ]);
      commit("HIGHLIGHT_DIRECTORY", null);
      commit("HIGHLIGHT_IMAGE", null);
    },
    reset_temporary_structure({ commit }) {
      commit("RESET_TEMPORARY_STRUCTURE");
    },
    reset_temporary_path({ commit }) {
      commit("RESET_TEMPORARY_PATH");
    },
  },
  getters: {
    path_structure: (state) => {
      if (!state.images.length) return {};

      let paths = [];
      state.images.forEach((image) => {
        paths = [...paths, image.file_path.split("/")];
      });

      const path_structure = paths.reduce((structure, path) => {
        if (path.length) {
          let ref = (structure[path[0]] = structure[path[0]] || {});
          for (const [i, dir] of path.entries()) {
            if (i > 0) {
              ref = ref[dir] = ref[dir] || {};
            }
          }
        }
        return structure;
      }, {});

      return path_structure;
    },
    current_directory: (state, getters) => {
      if (state.current_temporary_path.length) return {};
      let ref = getters.path_structure;
      state.current_path.forEach((dir) => {
        ref = ref[dir];
      });
      return ref || {};
    },
    current_directory_images: (state) => {
      if (state.current_temporary_path.length) return [];
      return state.images.filter(
        (image) => image.file_path == state.current_path.join("/")
      );
    },
    current_temporary_directory: (state) => {
      if (!Object.keys(state.temporary_directory_structure).length) return {};
      const parent_path = state.current_path.length
        ? state.current_path.join("/")
        : "~";
      let ref = state.temporary_directory_structure[parent_path];
      state.current_temporary_path.forEach((dir) => {
        ref = ref[dir];
      });
      return ref || {};
    },
    selected_image_ids: (state) => {
      return state.selected_images.map((i) => i.id);
    },
  },
};
