import moment from "moment";

import {
  get_staff,
  update_staff,
  create_staff,
  validate_staff_login,
} from "../requests";
import { SHOP_ADMIN_ROUTE } from "@/router/shop_admin_route";

function get_route_staff_permissions(path, route) {
  let route_config = {};

  if (route.children) {
    route.children.forEach((child_route) => {
      const child_route_config = get_route_staff_permissions(
        [path, child_route.path].join("/"),
        child_route
      );
      route_config = { ...route_config, ...child_route_config };
    });
  }

  route_config[path] = route.meta?.staff_permission ?? null;

  return route_config;
}

export default {
  namespaced: true,
  state: {
    staff: [],
    selected_staff: null,
    logged_in_staff: null,
    is_loading: false,
    is_validating: false,
    staff_datatable_key: 1,
  },
  mutations: {
    SET_STAFF(state, staff) {
      state.staff = staff;
    },
    SET_SELECTED_STAFF(state, staff) {
      state.selected_staff = staff;
    },
    SET_LOGGED_IN_STAFF(state, staff) {
      state.logged_in_staff = staff;
    },
    SET_IS_LOADING(state, loading) {
      state.is_loading = loading;
    },
    SET_IS_VALIDATING(state, loading) {
      state.is_validating = loading;
    },
    SET_UPDATED_STAFF(state, updated_staff) {
      const index = state.staff.findIndex(
        (staff) => staff.id == updated_staff.id
      );
      if (index === -1) return;
      state.staff[index] = updated_staff;
    },
    ADD_NEW_STAFF(state, new_staff) {
      state.staff = [new_staff, ...state.staff];
    },
    REFRESH_DATATABLE(state) {
      state.staff_datatable_key++;
    },
  },
  actions: {
    async get_staff_members({ commit }) {
      commit("SET_IS_LOADING", true);
      try {
        const { data: staff } = await get_staff();
        commit("SET_STAFF", staff);
      } finally {
        commit("SET_IS_LOADING", false);
      }
    },
    async update_staff_member({ commit }, { staff_id, params }) {
      commit("SET_IS_LOADING", true);
      try {
        const { data: updated_staff } = await update_staff(staff_id, params);
        commit("SET_UPDATED_STAFF", updated_staff);
        commit("REFRESH_DATATABLE");
      } finally {
        commit("SET_IS_LOADING", false);
      }
    },
    async create_staff_member({ commit }, { name, role, pin }) {
      commit("SET_IS_LOADING", true);
      try {
        const { data: new_staff } = await create_staff(name, role, pin);
        commit("ADD_NEW_STAFF", new_staff);
        commit("REFRESH_DATATABLE");
      } finally {
        commit("SET_IS_LOADING", false);
      }
    },
    async validate_staff_member({ commit }, { staff, pin }) {
      commit("SET_IS_VALIDATING", true);
      try {
        await validate_staff_login(staff.id, pin);
        commit("SET_LOGGED_IN_STAFF", staff);
      } finally {
        commit("SET_IS_VALIDATING", false);
      }
    },
    select_staff({ commit }, staff) {
      commit("SET_SELECTED_STAFF", staff);
    },
    async clear_logged_in_staff({ state, commit, dispatch }) {
      if (state.logged_in_staff) {
        const staff = { ...state.logged_in_staff };
        staff.last_seen_at = moment().utc().format();
        await dispatch("update_staff_member", {
          staff_id: staff.id,
          params: staff,
        });
      }
      commit("SET_LOGGED_IN_STAFF", null);
    },
  },
  getters: {
    active_staff: (state) => {
      return state.staff.filter((member) => member.is_active);
    },
    logged_in_staff_first_name: (state) => {
      return state.logged_in_staff
        ? state.logged_in_staff?.name.split(" ")[0]
        : "";
    },
    staff_accessible_paths: (state) => {
      const config = get_route_staff_permissions("", SHOP_ADMIN_ROUTE);

      if (!state.logged_in_staff) return Object.keys(config);

      const staff_role = state.logged_in_staff.role;

      const accessible_paths = Object.entries(config)
        .map(([path, permissions]) => {
          if (!permissions || permissions.includes(staff_role)) return path;
        })
        .filter((path) => path);

      return accessible_paths;
    },
  },
};
