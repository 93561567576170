import {
  get_shop_reviews,
  update_shop_review,
  delete_shop_review,
} from "@/requests";

function clean_filters() {
  return {
    cursor: null,
    sorting_cursor: "timestamp__asc__",
    rating_lt: null,
    rating_gte: null,
    timestamp_gte: null,
    timestamp_lte: null,
    customer_name: null,
    include_inactive: true,
    limit: 10,
  };
}

export default {
  namespaced: true,
  state: {
    reviews: [],
    total_results: 0,
    is_loading_reviews: false,
    filters: clean_filters(),
    datatable_options: {},
    prev_page: null,
    next_page: null,
    prev_page_sorting: null,
    next_page_sorting: null,
  },
  mutations: {
    SET_REVIEWS(state, reviews) {
      state.reviews = reviews;
    },
    ADD_REVIEWS(state, reviews) {
      state.reviews.push(...reviews);
    },
    SET_IS_LOADING_REVIEWS(state, loading) {
      state.is_loading_reviews = loading;
    },
    UPDATE_REVIEW_IN_REVIEWS(state, review) {
      state.reviews = [
        ...state.reviews.map((r) => (r.id !== review.id ? r : review)),
      ];
    },
    UPSERT_FILTERS(state, filters) {
      Object.assign(state.filters, filters);
    },
    SET_DATATABLE_OPTIONS(state, options) {
      state.datatable_options = options;
    },
    SET_CURSORS(state, cursors) {
      state.prev_page = cursors.prev_page;
      state.next_page = cursors.next_page;
      state.prev_page_sorting = cursors.prev_page_sorting;
      state.next_page_sorting = cursors.next_page_sorting;
    },
    SET_TOTAL_RESULTS(state, total) {
      state.total_results = total;
    },
  },
  actions: {
    set_datatable_options({ commit }, options) {
      commit("SET_DATATABLE_OPTIONS", options);
    },
    reset_datatable_with_sorting({ state, commit }, sorting_cursor) {
      const default_datatable_options = {
        itemsLength: 0,
        itemsPerPage: state.filters.limit,
        page: 1,
        pageCount: 0,
        pageStart: 0,
        pageStop: 0,
      };
      commit("SET_DATATABLE_OPTIONS", default_datatable_options);
      commit("SET_CURSORS", {
        next_page: null,
        prev_page: null,
        next_page_sorting: null,
        prev_page_sorting: sorting_cursor,
      });
    },
    async set_filters({ commit }, filters) {
      commit("UPSERT_FILTERS", filters);
    },
    async get_reviews({ state, commit, dispatch, rootState }, clean = false) {
      commit("SET_IS_LOADING_REVIEWS", true);
      if (clean) {
        const [sorting_cursor_start, direction] =
          state.filters.sorting_cursor.split("__");
        const sorting_cursor = `${sorting_cursor_start}__${direction}__`;
        dispatch("reset_datatable_with_sorting", sorting_cursor);
      }
      try {
        const {
          data: {
            items,
            prev_page,
            next_page,
            prev_page_sorting,
            next_page_sorting,
            total_results,
          },
        } = await get_shop_reviews(rootState.ShopStore.shop.id, state.filters);

        commit("SET_REVIEWS", items);
        commit("SET_TOTAL_RESULTS", total_results);
        commit("SET_CURSORS", {
          prev_page,
          next_page,
          prev_page_sorting,
          next_page_sorting,
        });
      } catch (e) {
        console.error(e);
      } finally {
        commit("SET_IS_LOADING_REVIEWS", false);
      }
    },
    clear_search({ commit }) {
      commit("SET_FILTERS", clean_filters());
    },
    async update_review({ commit }, { review_id, reply_text }) {
      try {
        const { data: review } = await update_shop_review(review_id, {
          reply_text,
        });
        commit("UPDATE_REVIEW_IN_REVIEWS", review);
      } catch (e) {
        console.error(e);
      }
    },
    async delete_review({ commit }, review_id) {
      try {
        const { data: review } = await delete_shop_review(review_id);
        commit("UPDATE_REVIEW_IN_REVIEWS", review);
      } catch (e) {
        console.error(e);
      }
    },
  },
};
