<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="12.395"
    height="12.395"
    viewBox="0 0 12.395 12.395"
  >
    <path
      stroke-width="0.2px"
      d="M11.146 5.049h-6.1a.61.61 0 1 0 0 1.22h6.1a.61.61 0 0 0 0-1.22zm0 2.439h-6.1a.61.61 0 1 0 0 1.22h6.1a.61.61 0 0 0 0-1.22zM12.366 2H3.829A1.829 1.829 0 0 0 2 3.829v6.1a1.829 1.829 0 0 0 1.829 1.829H10.9l2.256 2.262a.61.61 0 0 0 .433.177.512.512 0 0 0 .232-.049.61.61 0 0 0 .378-.561V3.829A1.829 1.829 0 0 0 12.366 2zm.61 10.116l-1.4-1.4a.61.61 0 0 0-.433-.177H3.829a.61.61 0 0 1-.61-.61v-6.1a.61.61 0 0 1 .61-.61h8.537a.61.61 0 0 1 .61.61z"
      transform="translate(-1.9 -1.9)"
    />
  </svg>
</template>

<script>
export default {
  name: "Chat",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  fill: currentColor;
  stroke: currentColor;
}
</style>
