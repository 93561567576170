<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16.484"
    height="16.484"
    viewBox="0 0 16.484 16.484"
  >
    <path
      d="M11.151 4.247a1.374 1.374 0 1 0-1.373-1.373 1.378 1.378 0 0 0 1.373 1.373zM8.61 6.583L6.852 15.45a.684.684 0 0 0 .673.817h.055a.69.69 0 0 0 .673-.536l1.113-4.959 1.442 1.374v3.434a.687.687 0 0 0 1.374 0v-3.873a1.366 1.366 0 0 0-.426-1l-1.017-.968.412-2.061a5.029 5.029 0 0 0 2.995 1.655.687.687 0 0 0 .2-1.36 3.341 3.341 0 0 1-2.37-1.6l-.687-1.1a1.474 1.474 0 0 0-1.82-.573L6.838 5.813A1.381 1.381 0 0 0 6 7.084v1.628a.687.687 0 0 0 1.374 0V7.063z"
      transform="translate(6 1) translate(-6 -1) translate(-1.879 -.47)"
    />
  </svg>
</template>

<script>
export default {
  name: "Collection",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  fill: currentColor;
}
</style>
