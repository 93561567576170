<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="22.374"
    height="19.521"
    viewBox="0 0 22.374 19.521"
  >
    <g id="Group_776" transform="translate(-711.044 -348.061)">
      <path
        id="_13352081061536572528"
        fill="#ff8080"
        stroke="#ff8080"
        d="M2.711 5.59a.427.427 0 0 1-.3-.125L.126 3.181a.427.427 0 0 1 .6-.6L2.694 4.54 6.62.143a.427.427 0 0 1 .638.569L3.03 5.447a.428.428 0 0 1-.307.143z"
        transform="translate(718.557 356.691)"
      />
      <g id="Group_775" transform="translate(-7.964 55.721)">
        <g id="_12952447441571183074" transform="translate(720 293.254)">
          <path
            id="Path_738"
            d="M4.009 6.025a3.5 3.5 0 0 0 0 5.019l5.018-5.019a3.649 3.649 0 0 0-5.018 0z"
            class="cls-2"
            transform="translate(-2.95 -4.966)"
          />
          <path
            id="Path_739"
            d="M26.319 6.009a3.5 3.5 0 0 0-5.019 0l5.019 5.019a3.649 3.649 0 0 0 0-5.019z"
            class="cls-2"
            transform="translate(-6.91 -4.95)"
          />
          <path
            id="Line_84"
            d="M0 1.568L1.568 0"
            class="cls-2"
            transform="translate(2.392 16.507)"
          />
          <path
            id="Line_85"
            d="M1.568 1.568L0 0"
            class="cls-2"
            transform="translate(16.507 16.507)"
          />
          <circle
            id="Ellipse_70"
            cx="7.058"
            cy="7.058"
            r="7.058"
            fill="none"
            stroke="#ff8080"
            stroke-miterlimit="10"
            stroke-width="1.5px"
            transform="translate(3.176 3.176)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ClockDone",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  .cls-2 {
    stroke: currentColor;
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 1.5px;
    stroke-linejoin: round;
  }
}
</style>
