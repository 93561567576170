<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="22.374"
    height="19.521"
    viewBox="0 0 22.374 19.521"
  >
    <g id="Group_771" transform="translate(-715.008 -259.34)">
      <g id="_12952447441571183074" transform="translate(716 260.254)">
        <path
          id="Path_738"
          d="M4.009 6.025a3.5 3.5 0 0 0 0 5.019l5.018-5.019a3.649 3.649 0 0 0-5.018 0z"
          class="cls-1"
          transform="translate(-2.95 -4.966)"
        />
        <path
          id="Path_739"
          d="M26.319 6.009a3.5 3.5 0 0 0-5.019 0l5.019 5.019a3.649 3.649 0 0 0 0-5.019z"
          class="cls-1"
          transform="translate(-6.91 -4.95)"
        />
        <path
          id="Line_84"
          d="M0 1.568L1.568 0"
          class="cls-1"
          transform="translate(2.392 16.507)"
        />
        <path
          id="Line_85"
          d="M1.568 1.568L0 0"
          class="cls-1"
          transform="translate(16.507 16.507)"
        />
        <circle
          id="Ellipse_70"
          cx="7.058"
          cy="7.058"
          r="7.058"
          fill="none"
          stroke="#54c4c9"
          stroke-miterlimit="10"
          stroke-width="1.5px"
          transform="translate(3.176 3.176)"
        />
      </g>
      <g id="_8563601801543238914" transform="translate(723.324 267.847)">
        <path
          id="Line_33"
          d="M0 0L0 5.762"
          class="cls-3"
          transform="translate(2.881)"
        />
        <path
          id="Line_34"
          d="M0 0L5.762 0"
          class="cls-3"
          transform="translate(0 2.881)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ClockAdd",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  .cls-1,
  .cls-3 {
    fill: none;
    stroke: currentColor;
    stroke-width: 1.5px;
    stroke-linejoin: round;
  }
  .cls-1 {
    stroke-miterlimit: 10;
  }
  .cls-3 {
    stroke-linecap: round;
  }
}
</style>
