import firebase from "firebase/app";
import "firebase/auth";
import UserStore from "@/store/UserStore";

import { SHOP_ADMIN_ROUTE } from "@/router/shop_admin_route";
import { NOT_FOUND_ROUTE } from "@/router/not_found_route";

async function super_admin_guard(to, from, next) {
  const user = await firebase.auth().currentUser;
  if (user) {
    const id_token_result = await user.getIdTokenResult();
    if (
      id_token_result.claims["super_admin"] ||
      id_token_result.claims["brand_manager"]
    ) {
      next();
      return;
    }
  } else if (!["/", "/login"].includes(to.path)) {
    let next_url = to.path;
    if (to.query) {
      next_url += "?";
      next_url += Object.keys(to.query)
        .map((key) => `${key}=${to.query[key]}`)
        .join("&");
    }
    next({ path: "/login", query: { next_url } });
    return;
  } else {
    next("/login");
  }
}

function staff_login_guard(to, from, next) {
  if (UserStore.state.user) {
    next();
    return;
  }
  next("/login");
}

function only_super_admin(to, from, next) {
  if (!UserStore.state.is_super_admin) {
    next("/admin");
    return;
  }
  next();
}

export default [
  SHOP_ADMIN_ROUTE,
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login"),
  },
  {
    path: "/staff_login",
    name: "StaffLogin",
    beforeEnter: staff_login_guard,
    component: () =>
      import(/* webpackChunkName: "staff_login" */ "@/views/staff/StaffLogin"),
  },
  {
    path: "/admin",
    name: "admin",
    beforeEnter: super_admin_guard,
    component: () => import(/* webpackChunkName: "admin" */ "@/views/admin"),
    redirect: { name: "admin-brands" },
    children: [
      {
        path: "shops",
        name: "admin-shops",
        beforeEnter: super_admin_guard,
        component: () => import("@/views/admin/shops/index"),
        meta: {
          breadcrumb: [{ name: "Shops" }],
        },
      },
      {
        path: "shops/:id",
        name: "admin-shops-id",
        beforeEnter: super_admin_guard,
        component: () => import("@/views/admin/shops/_id"),
        meta: {
          breadcrumb: [
            { name: "Shops", to_name: "admin-shops" },
            { name: "__selected_shop_name__" },
          ],
        },
      },
      {
        path: "register",
        name: "admin-register",
        beforeEnter: super_admin_guard,
        component: () => import("@/views/admin/register"),
        meta: {
          breadcrumb: [
            { name: "Shops", to_name: "admin-shops" },
            { name: "Register A Shop" },
          ],
        },
      },
      {
        path: "brands",
        beforeEnter: (to, from, next) =>
          super_admin_guard(to, from, next) && only_super_admin(to, from, next),
        name: "admin-brands",
        component: () => import("@/views/admin/brands/index"),
        meta: {
          breadcrumb: [{ name: "Brands" }],
        },
      },
      {
        path: "brands/new",
        name: "admin-brands-new",
        beforeEnter: (to, from, next) =>
          super_admin_guard(to, from, next) && only_super_admin(to, from, next),
        component: () => import("@/views/admin/brands/_id/edit"),
        meta: {
          breadcrumb: [
            { name: "Brands", to_name: "admin-brands" },
            { name: "Create" },
          ],
        },
      },
      {
        path: "brands/:brand_id",
        beforeEnter: super_admin_guard,
        name: "admin-brands-id",
        component: () => import("@/views/admin/brands/_id/index"),
        redirect: { name: "admin-brands-id-home-page" },
        children: [
          {
            path: "edit",
            name: "admin-brands-id-edit",
            component: () => import("@/views/admin/brands/_id/edit"),
            meta: {
              is_editing: true,
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Settings" },
              ],
            },
          },
          {
            path: "zones",
            name: "admin-brands-id-zones",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/brand-zones"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Manage __brand_zone_label__s" },
              ],
            },
          },
          {
            path: "new-promotion",
            name: "admin-brands-id-new-promotion",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/new-promotion"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Showcase My Shops" },
              ],
            },
          },
          {
            path: "promotions",
            name: "admin-brands-id-promotions",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/promotions"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Showcase My Shops" },
              ],
            },
          },
          {
            path: "new-news",
            name: "admin-brands-id-new-news",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/new-news"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "News List", to_name: "admin-brands-id-news" },
                { name: "News" },
              ],
            },
          },
          {
            path: "new-page",
            name: "admin-brands-id-new-page",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/new-news"),
            props: { customPage: true },
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Other Pages", to_name: "admin-brands-id-pages" },
                { name: "Create New Page" },
              ],
            },
          },
          {
            path: "news",
            name: "admin-brands-id-news",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/news"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "News" },
              ],
            },
          },
          {
            path: "home-page",
            name: "admin-brands-id-home-page",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/home-page"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Home Page" },
              ],
            },
          },
          {
            path: "pages",
            name: "admin-brands-id-pages",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/custom-pages"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Other Pages" },
              ],
            },
          },
          {
            path: "users",
            name: "admin-brands-id-users",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/users"),
            meta: {
              breadcrumb: [
                { name: "Brands", to_name: "admin-brands" },
                { name: "__selected_brand_name__", to_name: "admin-brands-id" },
                { name: "Users" },
              ],
            },
          },
          {
            path: "requests",
            beforeEnter: super_admin_guard,
            component: () => import("@/views/admin/brands/_id/requests-router"),
            children: [
              {
                path: "",
                name: "admin-brands-id-requests",
                beforeEnter: super_admin_guard,
                component: () => import("@/views/admin/brands/_id/requests"),
                meta: {
                  breadcrumb: [
                    { name: "Brands", to_name: "admin-brands" },
                    {
                      name: "__selected_brand_name__",
                      to_name: "admin-brands-id",
                    },
                    { name: "Sign Up Requests" },
                  ],
                },
              },
              {
                path: "add_a_business",
                name: "admin-register-aab",
                beforeEnter: super_admin_guard,
                component: () => import("@/views/admin/register"),
                meta: {
                  breadcrumb: [
                    { name: "Brands", to_name: "admin-shops" },
                    {
                      name: "__selected_brand_name__",
                      to_name: "admin-brands-id",
                    },
                    {
                      name: "Sign Up Requests",
                      to_name: "admin-brands-id-requests",
                    },
                    { name: "New Shop Application" },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Logout"),
  },
  NOT_FOUND_ROUTE,
];
