<template>
  <v-dialog
    v-model="dialog"
    width="500"
    content-class="br-16"
    style="z-index: 2000"
  >
    <v-card>
      <v-card-title
        v-if="header"
        class="text-left primary white--text font-weight-bold pb-4"
      >
        {{ header }}
      </v-card-title>

      <v-card-text class="py-8">
        <slot></slot>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          v-if="$listeners.delete"
          color="red"
          rounded
          text
          :loading="loading_id == 'delete' && loading"
          @click="$emit('delete')"
        >
          Delete
        </v-btn>
        <v-spacer />
        <v-btn
          color="dark-grey"
          rounded
          text
          @click="
            $emit('close');
            dialog = false;
          "
        >
          Cancel
        </v-btn>
        <v-btn
          :color="confirm_color"
          :disabled="disabled"
          :loading="loading_id == 'confirm' && loading"
          rounded
          text
          @click="$emit('agree')"
        >
          {{ confirm_text }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "AsyncAdminDialog",
  props: {
    header: {
      type: String,
      default: "Async Admin Dialog",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    confirm_text: {
      type: String,
      default: `Confirm`,
    },
    confirm_color: {
      type: String,
      default: `primary`,
    },
    loading_id: {
      type: String,
      default: "confirm",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree(value) {
      this.dialog = false;
      this.resolve(value);
    },
    cancel() {
      this.dialog = false;
      this.resolve(false);
    },
  },

  provide() {
    return { agree: this.agree, cancel: this.cancel };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-dialog {
  z-index: 2000;
}
</style>
