<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="13.073"
    height="17.031"
    viewBox="0 0 13.073 17.031"
  >
    <g id="_17094183761571183081" transform="translate(-6.4 -3.4)">
      <path
        id="Path_540"
        d="M18.873 9.937c0 5.277-5.937 9.894-5.937 9.894S7 15.213 7 9.937a5.937 5.937 0 0 1 11.873 0z"
        class="cls-1"
      />
      <circle
        id="Ellipse_47"
        cx="2"
        cy="2"
        r="2"
        class="cls-1"
        transform="translate(11 8)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MapPin",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  .cls-1 {
    fill: none;
    stroke: currentColor;
    stroke-linejoin: round;
    stroke-miterlimit: 10;
    stroke-width: 1.2px;
  }
}
</style>
