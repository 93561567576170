<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.089"
    height="18.364"
    viewBox="0 0 17.089 18.364"
  >
    <path
      fill="#666"
      class="fillable"
      d="M19.076 12.518l-.345-.2a1.312 1.312 0 0 1 0-2.272l.345-.2a1.967 1.967 0 0 0 .72-2.688l-.656-1.136a1.97 1.97 0 0 0-2.688-.72l-.345.2a1.312 1.312 0 0 1-1.967-1.136v-.4A1.97 1.97 0 0 0 12.173 2h-1.312a1.97 1.97 0 0 0-1.968 1.968v.4A1.312 1.312 0 0 1 6.926 5.5l-.345-.2a1.971 1.971 0 0 0-2.688.72l-.657 1.139a1.966 1.966 0 0 0 .72 2.688l.346.2a1.312 1.312 0 0 1 0 2.272l-.345.2a1.966 1.966 0 0 0-.72 2.688l.656 1.136a1.97 1.97 0 0 0 2.688.72l.345-.2A1.312 1.312 0 0 1 8.893 18v.4a1.97 1.97 0 0 0 1.968 1.968h1.312A1.97 1.97 0 0 0 14.14 18.4V18a1.312 1.312 0 0 1 1.968-1.136l.345.2a1.971 1.971 0 0 0 2.688-.72l.656-1.136a1.966 1.966 0 0 0-.721-2.69zm-7.56 1.944a3.279 3.279 0 1 1 3.284-3.28 3.283 3.283 0 0 1-3.283 3.28z"
      transform="translate(-2.972 -2)"
    />
  </svg>
</template>

<script>
export default {
  name: "SettingsCog",
};
</script>
