import { firestoreAction } from "vuexfire";
import { db } from "@/db";
import moment from "moment";
import { pickBy } from "lodash";

import {
  SERVICE_TYPES_INFO,
  categories_active_for_service_id,
  service_types_active_for_category_id,
} from "@/shared/services";

export const ORDER_DOCS_TO_SKIP = ["--stats--"];
export const PRODUCTS_DOCS_TO_SKIP = ["--stats--"];
export const VOUCHER_DOCS_TO_SKIP = ["--stats--"];

function parse_takeaway_fulfilment_to_fulfilment_date(order) {
  if (order.fulfilment_date) return order;
  const is_asap = order.takeaway_fulfil_asap;

  if (is_asap) return order;
  let pickup_date = order.takeaway_pickup_date;
  let pickup_time = order.takeaway_pickup_time;

  if (!pickup_date && !pickup_time) {
    order.takeaway_fulfil_asap = true;
    return order;
  }

  let fulfilment_date = [
    moment(pickup_date, "DD/MM/YYYY").format("YYYY-MM-DD"),
    moment(pickup_time, "H:mm").format("HH:mm"),
  ].join(" ");

  order.fulfilment_date = fulfilment_date;

  return order;
}
export default {
  namespaced: true,
  state: {
    shop_id: null,
    shop: null,
    notifications: [],
    products: [],
    vouchers: [],
    tips: {},

    orders: [],
    in_house_orders: [],
    takeaway_orders: [],
    hospitality_delivery_orders: [],
    collaboration_orders: [],
    routes: [],

    categories: [],
    product_categories: {},
    shop_products_categories: {},
    shop_categories_config: {},
    custom_categories: {},
    main_categories: {},
    hospitality_cuisines: {},

    dining_orders_settings_panel: 0,
    dining_orders_split_view: false,
    directory_shops: {},
  },
  mutations: {
    SET_SHOP_ID(state, { shop_id }) {
      state.shop_id = shop_id;
    },
    TOGGLE_DINING_ORDERS_SETTINGS_PANEL(state, { payload }) {
      state.dining_orders_settings_panel = payload;
    },
    TOGGLE_DINING_ORDERS_SPLIT_VIEW(state, { value }) {
      state.dining_orders_split_view = value;
    },
  },
  actions: {
    set_shop_id(context, { shop_id }) {
      context.commit("SET_SHOP_ID", { shop_id });
    },
    toggle_dining_orders_settings_panel(context, { payload }) {
      context.commit("TOGGLE_DINING_ORDERS_SETTINGS_PANEL", { payload });
    },
    toggle_dining_orders_split_view(context, { value }) {
      context.commit("TOGGLE_DINING_ORDERS_SPLIT_VIEW", { value });
    },
    bind_shop: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      if (shop_id === null) return;
      await context.bindFirestoreRef(
        "shop",
        db.collection("shops").doc(shop_id)
      );
    }),
    unbind_shop: firestoreAction((context) => {
      return context.unbindFirestoreRef("shop");
    }),
    bind_categories: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      if (shop_id === null) return;
      await context.bindFirestoreRef("categories", db.collection("categories"));
    }),
    unbind_categories: firestoreAction((context) => {
      return context.unbindFirestoreRef("categories");
    }),
    bind_shop_products_categories: firestoreAction(async (context) => {
      let shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "shop_products_categories",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("products")
          .doc("--stats--")
      );
    }),
    unbind_shop_products_categories: firestoreAction((context) => {
      return context.unbindFirestoreRef("shop_products_categories");
    }),
    bind_shop_products_categories_config: firestoreAction(async (context) => {
      let shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "shop_categories_config",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("categories")
          .doc("config")
      );
    }),
    unbind_shop_products_categories_config: firestoreAction((context) => {
      return context.unbindFirestoreRef("shop_categories_config");
    }),
    bind_custom_categories: firestoreAction(async (context) => {
      let shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "custom_categories",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("categories")
          .doc("custom_categories")
      );
    }),
    unbind_custom_categories: firestoreAction((context) => {
      return context.unbindFirestoreRef("custom_categories");
    }),
    bind_product_categories: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      if (shop_id === null) return;
      await context.bindFirestoreRef(
        "product_categories",
        db.collection("product_categories").doc("--all--")
      );
    }),
    unbind_product_categories: firestoreAction((context) => {
      return context.unbindFirestoreRef("product_categories");
    }),
    bind_products: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "products",
        db.collection("shops").doc(shop_id).collection("products")
      );
    }),
    unbind_products: firestoreAction((context) => {
      return context.unbindFirestoreRef("products");
    }),
    bind_orders: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "orders",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("orders")
          .where("service", "in", ["collection", "delivery"])
      );
    }),
    unbind_orders: firestoreAction((context) => {
      return context.unbindFirestoreRef("orders");
    }),
    bind_in_house_orders: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "in_house_orders",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("orders")
          .where("service", "==", "in_house")
          .where("status", "in", ["new", "processing"])
      );
    }),
    unbind_in_house_orders: firestoreAction((context) => {
      return context.unbindFirestoreRef("in_house_orders");
    }),
    bind_takeaway_orders: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "takeaway_orders",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("orders")
          .where("service", "==", "takeaway")
          .where("status", "in", ["new", "processing"])
      );
    }),
    unbind_takeaway_orders: firestoreAction((context) => {
      return context.unbindFirestoreRef("takeaway_orders");
    }),
    bind_hospitality_delivery_orders: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "hospitality_delivery_orders",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("orders")
          .where("service", "==", "hospitality_delivery")
          .where("status", "in", ["new", "processing"])
      );
    }),
    unbind_hospitality_delivery_orders: firestoreAction((context) => {
      return context.unbindFirestoreRef("hospitality_delivery_orders");
    }),
    bind_routes: firestoreAction(async (context) => {
      let shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "routes",
        db.collection("shops").doc(shop_id).collection("routes")
      );
    }),
    unbind_routes: firestoreAction((context) => {
      return context.unbindFirestoreRef("routes");
    }),
    bind_collaboration_orders: firestoreAction(async (context) => {
      const active_brand_ids =
        context.rootGetters["AdminStore/shop_active_brand_ids"];

      await context.bindFirestoreRef(
        "collaboration_orders",
        db
          .collection("collaboration_orders")
          .where("open", "==", true)
          .where("brand_ids", "array-contains-any", active_brand_ids)
          .where("fulfilment_date", "==", moment().format("YYYY-MM-DD"))
      );
    }),
    unbind_collaboration_orders: firestoreAction((context) => {
      return context.unbindFirestoreRef("collaboration_orders");
    }),
    bind_notifications: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      if (shop_id === null) return;
      await context.bindFirestoreRef(
        "notifications",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("notifications")
          .where("seen", "==", false)
      );
    }),
    unbind_notifications: firestoreAction((context) => {
      return context.unbindFirestoreRef("notifications");
    }),
    bind_main_categories: firestoreAction(async (context) => {
      let shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "main_categories",
        db
          .collection("shops")
          .doc(shop_id)
          .collection("categories")
          .doc("mains")
      );
    }),
    unbind_main_categories: firestoreAction((context) => {
      return context.unbindFirestoreRef("main_categories");
    }),
    bind_vouchers: firestoreAction(async (context) => {
      const shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "vouchers",
        db.collection("shops").doc(shop_id).collection("vouchers")
      );
    }),
    unbind_vouchers: firestoreAction((context) => {
      return context.unbindFirestoreRef("vouchers");
    }),
    bind_directory_shops: firestoreAction(async (context) => {
      await context.bindFirestoreRef(
        "directory_shops",
        db.collection("directory_shops").doc("--all--")
      );
    }),
    unbind_directory_shops: firestoreAction((context) => {
      return context.unbindFirestoreRef("directory_shops");
    }),
    bind_cuisines: firestoreAction(async (context) => {
      return context.bindFirestoreRef(
        "hospitality_cuisines",
        db.collection("cuisines").doc("--all--")
      );
    }),
    unbind_cuisines: firestoreAction((context) => {
      return context.unbindFirestoreRef("hospitality_cuisines");
    }),
    bind_tips: firestoreAction(async (context) => {
      let shop_id = context.state.shop_id;
      await context.bindFirestoreRef(
        "tips",
        db.collection("shops").doc(shop_id).collection("admin_data").doc("tips")
      );
    }),
    unbind_tips: firestoreAction((context) => {
      return context.unbindFirestoreRef("tips");
    }),
    async bind_admin_data(context, { shop_id }) {
      await context.dispatch("set_shop_id", { shop_id });
      await Promise.all([
        context.dispatch("bind_shop"),
        context.dispatch("bind_products"),
        context.dispatch("bind_orders"),
        context.dispatch("bind_in_house_orders"),
        context.dispatch("bind_takeaway_orders"),
        context.dispatch("bind_hospitality_delivery_orders"),
        context.dispatch("bind_categories"),
        context.dispatch("bind_product_categories"),
        context.dispatch("bind_shop_products_categories"),
        context.dispatch("bind_shop_products_categories_config"),
        context.dispatch("bind_main_categories"),
        context.dispatch("bind_custom_categories"),
        context.dispatch("bind_routes"),
        context.dispatch("bind_notifications"),
        context.dispatch("bind_vouchers"),
        context.dispatch("bind_directory_shops"),
        context.dispatch("bind_cuisines"),
        context.dispatch("bind_tips"),
        context.dispatch("StaffStore/get_staff_members", null, { root: true }),
      ]);
    },
    unbind_admin_data(context) {
      context.dispatch("unbind_routes");
      context.dispatch("unbind_product_categories");
      context.dispatch("unbind_shop_products_categories");
      context.dispatch("unbind_shop_products_categories_config");
      context.dispatch("unbind_main_categories");
      context.dispatch("unbind_custom_categories");
      context.dispatch("unbind_categories");
      context.dispatch("unbind_orders");
      context.dispatch("unbind_in_house_orders");
      context.dispatch("unbind_takeaway_orders");
      context.dispatch("unbind_hospitality_delivery_orders");
      context.dispatch("unbind_products");
      context.dispatch("unbind_shop");
      context.dispatch("unbind_collaboration_orders");
      context.dispatch("unbind_notifications");
      context.dispatch("unbind_vouchers");
      context.dispatch("unbind_directory_shops");
      context.dispatch("unbind_cuisines");
      context.dispatch("unbind_tips");
      context.dispatch("set_shop_id", { shop_id: null });
    },
  },
  getters: {
    shop_name: (state) => state.shop?.name,
    shop_active_brand_ids: (state) => {
      let brands = state?.shop?.brand ?? null;
      if (!brands) return [];
      return Object.entries(brands)
        .filter(([, is_active]) => is_active)
        .map(([brand_id]) => brand_id);
    },
    important_update: (state) => {
      let notf = state.notifications;
      const important = notf.find((x) => x.type === "important_update");
      return important;
    },
    orders: (state) => {
      return state.orders.filter((o) => !ORDER_DOCS_TO_SKIP.includes(o.id));
    },
    takeaway_orders: (state) => {
      return state.takeaway_orders.map(
        parse_takeaway_fulfilment_to_fulfilment_date
      );
    },
    hospitality_delivery_orders: (state) => {
      return state.hospitality_delivery_orders.map(
        parse_takeaway_fulfilment_to_fulfilment_date
      );
    },
    products: (state) => {
      return state.products.filter(
        (o) => !PRODUCTS_DOCS_TO_SKIP.includes(o.id)
      );
    },
    vouchers: (state) => {
      return state.vouchers.filter((v) => !VOUCHER_DOCS_TO_SKIP.includes(v.id));
    },
    voucher_codes: (state) => {
      return state.vouchers
        .filter((v) => !VOUCHER_DOCS_TO_SKIP.includes(v.id))
        .map((v) => v.code);
    },
    shop_details_for_order_collaboration: (state) => {
      return {
        id: state.shop_id,
        name: state.shop.name,
        mobile_no: state.shop.mobile_no,
        phone_no: state.shop.phone_no,
        lat: state.shop.geolocation.lat,
        lng: state.shop.geolocation.lng,
        location: state.shop.location,
      };
    },
    has_collection_service: (state) => {
      if (!state.shop) return;
      // default shop.acquire_items is "{service: {}}"
      return state.shop.acquire_items.service.collection;
    },
    has_delivery_service: (state) => {
      if (!state.shop) return;
      // default shop.acquire_items is "{service: {}}"
      return state.shop.acquire_items.service.delivery;
    },
    has_in_house_service: (state) => {
      if (!state.shop) return;
      // default shop.acquire_items is "{service: {}}"
      return state.shop.acquire_items.service.in_house;
    },
    has_takeaway_service: (state) => {
      if (!state.shop) return;
      // default shop.acquire_items is "{service: {}}"
      return state.shop.acquire_items.service.takeaway;
    },
    has_hospitality_delivery_service: (state) => {
      if (!state.shop) return;
      // default shop.acquire_items is "{service: {}}"
      return state.shop.acquire_items.service.hospitality_delivery;
    },
    has_retail_service: (state, getters) => {
      return getters.has_collection_service || getters.has_delivery_service;
    },
    has_dining_service: (state, getters) => {
      return (
        getters.has_in_house_service ||
        getters.has_takeaway_service ||
        getters.has_hospitality_delivery_service
      );
    },
    has_all_dining_services: (state, getters) => {
      return (
        getters.has_in_house_service &&
        getters.has_takeaway_service &&
        getters.has_hospitality_delivery_service
      );
    },
    can_accept_orders: (state, getters) => {
      if (getters.is_directory_shop) return false;
      return Boolean(getters.shop_services.length);
    },
    all_product_categories: (state) => {
      return { ...state.product_categories, ...state.custom_categories };
    },
    shop_services: (state, getters) => {
      return Object.values({
        COLLECTION: {
          ...SERVICE_TYPES_INFO.collection,
          has_service: getters.has_collection_service,
        },
        DELIVERY: {
          ...SERVICE_TYPES_INFO.delivery,
          has_service: getters.has_delivery_service,
        },
        IN_HOUSE: {
          ...SERVICE_TYPES_INFO.in_house,
          has_service: getters.has_in_house_service,
        },
        TAKEAWAY: {
          ...SERVICE_TYPES_INFO.takeaway,
          has_service: getters.has_takeaway_service,
        },
        HOSPITALITY_DELIVERY: {
          ...SERVICE_TYPES_INFO.hospitality_delivery,
          has_service: getters.has_hospitality_delivery_service,
        },
      }).filter((service) => service.has_service);
    },
    dining_orders_length(state) {
      return (
        state.in_house_orders.length +
        state.takeaway_orders.length +
        state.hospitality_delivery_orders.length
      );
    },
    retail_orders_length(state) {
      return state.orders.filter((o) => o.status === "new").length;
    },
    is_directory_shop: (state) => {
      if (!state.shop_id || !state.directory_shops) return;
      return state.directory_shops[state.shop_id] || false;
    },
    active_categories: (state) => {
      return pickBy(state.shop_products_categories, (value) => value > 0);
    },
    active_categories_for_service_id: (state) => (service_id) => {
      if (!state.shop_categories_config || !state.shop_products_categories)
        return [];

      const config = Object.fromEntries(
        Object.entries(state.shop_products_categories).filter(
          ([, amount]) => amount > 0
        )
      );

      return categories_active_for_service_id(
        state.shop_categories_config,
        Object.keys(config),
        service_id
      );
    },
    active_categories_for_service_id__options:
      (state, getters) => (service_id) => {
        return getters
          .active_categories_for_service_id(service_id)
          .map((category_id) => ({
            value: category_id,
            text: getters.all_product_categories[category_id],
          }));
      },
    active_services_for_category_id: (state, getters) => (category_id) => {
      if (!state.shop_categories_config) return [];
      return service_types_active_for_category_id(
        getters.shop_services,
        state.shop_categories_config,
        category_id
      );
    },
    active_services_for_category_id__options:
      (state, getters) => (category_id) => {
        return getters
          .active_services_for_category_id(category_id)
          .map((service) => ({
            value: service.id,
            text: service.pretty_name,
          }));
      },
    sound_notification_file_name: (state) => {
      return state.shop?.sound_notification_file_name ?? "tone-0";
    },
    is_required_settings_complete(state) {
      const required_settings = {
        category:
          Object.entries(state.shop?.categories).some(
            ([, is_active]) => is_active
          ) || false,
        type: state.shop?.type !== null || false,
        location: state.shop?.location !== null || false,
        description_header: state.shop?.description_header !== null || false,
        email: state.shop?.email !== null || false,
        mobile_no: state.shop?.mobile_no !== null || false,
        business_hours:
          Object.values(state.shop?.business_hours).some(
            ({ is_open }) => is_open
          ) || false,
      };
      return Object.values(required_settings).every((state) => Boolean(state));
    },
  },
};
