<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="16.857"
    height="16.857"
    viewBox="0 0 16.857 16.857"
  >
    <g id="_12627537101543238871" transform="translate(.851 .85)">
      <rect
        id="Rectangle_313"
        width="9.744"
        height="9.744"
        class="cls-1"
        rx="2"
        transform="translate(5.413 5.413)"
      />
      <path
        id="Path_714"
        d="M4.282 11.887h-.761A1.521 1.521 0 0 1 2 10.366V3.521A1.521 1.521 0 0 1 3.521 2h6.845a1.521 1.521 0 0 1 1.521 1.521v.761"
        class="cls-1"
        transform="translate(-2.001 -2)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Copy",
};
</script>

<style lang="scss" scoped>
.custom-icon g {
  path,
  rect {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.7px;
  }
}
</style>
