<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="22.2"
    height="14.2"
    viewBox="0 0 22.2 14.2"
  >
    <g id="_2817315341595601896" transform="translate(-1.4 -8.4)">
      <path id="Path_532" d="M2 9h13.222v11.667h-7" class="cls-1" />
      <circle
        id="Ellipse_45"
        cx="1.5"
        cy="1.5"
        r="1.5"
        class="cls-1 strokable"
        transform="translate(18 19)"
      />
      <circle
        id="Ellipse_46"
        cx="1.5"
        cy="1.5"
        r="1.5"
        class="cls-1 strokable"
        transform="translate(5 19)"
      />
      <path
        id="Path_533"
        d="M19 21.556V13h4.667l3.111 3.889v4.667h-2.334"
        class="cls-1"
        transform="translate(-3.778 -.889)"
      />
      <path
        id="Line_14"
        d="M0 0L7 0"
        class="cls-1"
        transform="translate(4 12)"
      />
      <path
        id="Line_15"
        d="M0 0L7 0"
        class="cls-1"
        transform="translate(2 15)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Deliveries",
};
</script>

<style scoped>
.cls-1 {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 1.2px;
}
.custom-icon {
  fill: currentColor;
  stroke: currentColor;
}
</style>
