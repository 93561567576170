<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="-5 0 21.246 12.603"
    class="custom-icon"
  >
    <path
      d="M4.139 8.534a.652.652 0 0 1-.461-.191L.192 4.857a.652.652 0 0 1 .923-.923l3 3L10.107.218a.652.652 0 1 1 .973.869L4.626 8.316a.653.653 0 0 1-.468.218z"
      transform="translate(.5 .569)"
    />
  </svg>
</template>
<script>
export default {
  name: "CheckMark",
};
</script>
<style lang="scss" scoped>
.custom-icon {
  outline: none;
  path {
    fill: currentColor;
    stroke: currentColor;
  }
}
</style>
