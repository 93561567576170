export const days = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  0: "Sunday",
};

export const VOUCHER_TYPES = {
  CURRENCY: "currency",
  PERCENT: "percent",
};

export const DIETARY_TYPES = {
  dairy_free: "Dairy-free",
  gluten_free: "Gluten-free",
  halal: "Halal",
  kosher: "Kosher",
  nut_free: "Nut-Free",
  organic: "Organic",
  soy_free: "Soy-Free",
  vegan: "Vegan",
  vegan_friendly: "Vegan Friendly",
  vegetarian: "Vegetarian",
};

export const BOOKING_STATUS = {
  NEW: "new",
  CONFIRMED: "confirmed",
  ARRIVED: "arrived",
  SEATED: "seated",
  NO_SHOW: "no_show",
  CANCELLED: "cancelled",
  COMPLETED: "completed",
};

export const BOOKING_STATUS_CHIP_DATA = {
  [BOOKING_STATUS.NEW]: {
    color: "white",
    text: "Unconfirmed",
    outlined: true,
    icon: "mdi-clock",
    icon_color: "primary",
  },
  [BOOKING_STATUS.CONFIRMED]: {
    color: "white",
    text: "Confirmed",
    icon: "mdi-check-bold",
    icon_color: "primary",
  },
  [BOOKING_STATUS.ARRIVED]: {
    color: "#fffed9",
    text: "Arrived",
    icon: "mdi-map-marker-check-outline",
    icon_color: "primary",
  },
  [BOOKING_STATUS.SEATED]: {
    color: "#ddfffa",
    text: "Seated",
    icon: "$seated",
    icon_color: "primary",
  },
  [BOOKING_STATUS.NO_SHOW]: {
    color: "white",
    text: "No show",
    icon: "mdi-eye-off-outline",
    icon_color: "#bcbcbc",
  },
  [BOOKING_STATUS.COMPLETED]: {
    color: "#dcfed6",
    text: "Finished & Paid",
    icon: "$hand-money",
    icon_color: "primary",
  },
  [BOOKING_STATUS.CANCELLED]: {
    color: "#ffdddd",
    text: "Cancelled",
    icon: "mdi-close-thick",
    icon_color: "#bcbcbc",
  },
};

export const BOOKING_STATUS_ITEMS = [
  { text: "Unconfirmed", value: "new" },
  { text: "Confirmed", value: "confirmed" },
  { text: "Arrived", value: "arrived" },
  { text: "Seated", value: "seated" },
  { text: "No Show", value: "no_show" },
  { text: "Cancelled", value: "cancelled" },
  { text: "Finished & Paid", value: "completed" },
];
