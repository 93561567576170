<template>
  <svg
    class="custom-icon"
    width="15.928"
    height="14.755"
    viewBox="0 0 15.928 14.755"
  >
    <g transform="translate(.429 .485)">
      <g transform="translate(-.053 -.026)">
        <path
          id="Path_572"
          d="M15.113 7.233L13.84 5.606a.659.659 0 0 0-1 0l-1.277 1.627a.637.637 0 0 0 1 .784l.045-.06a5.734 5.734 0 1 1-1.55-4.983.638.638 0 0 0 .91-.891 7.012 7.012 0 1 0 1.954 5.7l.184.239a.637.637 0 1 0 1-.784z"
          class="cls-1"
          transform="translate(.053 .026)"
        />
        <path
          id="Path_573"
          d="M27.305 10.67a.638.638 0 0 0-.635.635v3.822a.526.526 0 0 0 .019.148.454.454 0 0 0 .05.134.4.4 0 0 0 .069.115l.019.024a.5.5 0 0 0 .091.084l.026.019 1 .753 1.529 1.147a.619.619 0 0 0 .382.127.638.638 0 0 0 .382-1.147l-.2-.153-.817-.612-.846-.638-.428-.318v-3.5a.638.638 0 0 0-.638-.635z"
          class="cls-1"
          transform="translate(-20.286 -8.115)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "ClockElapsed",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  .cls-1 {
    fill: currentColor;
    stroke: currentColor;
    stroke-width: 0.5px;
  }
}
</style>
