import firebase from "firebase/app";
import "firebase/auth";

export default {
  namespaced: true,
  state: {
    user: null,
    api_user_id: null,
    brand_id: null,
    is_super_admin: false,
    is_shop_admin: false,
    is_shop_staff: false,
    is_community_admin: false,
    is_login_loading: false,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    SET_IS_SUPER_ADMIN(state, is_super_admin) {
      state.is_super_admin = is_super_admin;
    },
    SET_IS_SHOP_ADMIN(state, is_shop_admin) {
      state.is_shop_admin = is_shop_admin;
    },
    SET_IS_SHOP_STAFF(state, is_shop_staff) {
      state.is_shop_staff = is_shop_staff;
    },
    SET_IS_COMMUNITY_ADMIN(state, is_community_admin) {
      state.is_community_admin = is_community_admin;
    },
    SET_IS_LOGIN_LOADING(state, loading) {
      state.is_login_loading = loading;
    },
    SET_USER_BRAND_ID(state, brand_id) {
      state.brand_id = brand_id;
    },
    SET_API_USER_ID(state, api_user_id) {
      state.api_user_id = api_user_id;
    },
  },
  actions: {
    async set_user(context, user) {
      context.commit("SET_USER", user);
      await context.dispatch("set_claims");
    },
    set_api_user_id({ commit }, api_user_id) {
      commit("SET_API_USER_ID", api_user_id);
    },
    set_login_loading({ commit }, loading) {
      commit("SET_IS_LOGIN_LOADING", loading);
    },
    async set_claims({ commit }) {
      let is_super_admin = false;
      let is_shop_admin = false;
      let is_shop_staff = false;
      let is_community_admin = false;
      let brand_id = null;

      const user = await firebase.auth().currentUser;
      if (user) {
        const id_token_result = await user.getIdTokenResult(true);
        is_super_admin = id_token_result?.claims?.super_admin ?? false;
        is_shop_admin = id_token_result?.claims?.shop_admin ?? false;
        is_shop_staff = id_token_result?.claims?.shop_staff ?? false;
        is_community_admin = id_token_result?.claims?.brand_manager ?? false;
        brand_id = id_token_result?.claims?.brand_id ?? false;
      }
      commit("SET_IS_SUPER_ADMIN", is_super_admin);
      commit("SET_IS_SHOP_ADMIN", is_shop_admin);
      commit("SET_IS_SHOP_STAFF", is_shop_staff);
      commit("SET_IS_COMMUNITY_ADMIN", is_community_admin);
      commit("SET_USER_BRAND_ID", brand_id);
    },
  },
  getters: {
    name: (state) => {
      let name = state.user?.displayName ?? undefined;
      if (name) return name;
      name = state.user?.email.split("@")[0] ?? "User";
      return name.includes(".") ? name.split(".") : name;
    },
  },
};
