<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15.76"
    height="14.314"
    viewBox="0 0 15.76 14.314"
  >
    <path
      fill="none"
      stroke="#666"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.3px"
      d="M16.46 3H2l5.784 6.84v4.728l2.892 1.446V9.84z"
      transform="translate(-1.35 -2.35)"
    />
  </svg>
</template>

<script>
export default {
  name: "Categories",
};
</script>
