<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_2"
    width="15.6"
    height="15.6"
    viewBox="0 0 15.6 15.6"
  >
    <g id="Menu">
      <path
        id="Path_627"
        d="M13.079 0H2.521A2.521 2.521 0 0 0 0 2.521v10.558A2.521 2.521 0 0 0 2.521 15.6h10.558a2.521 2.521 0 0 0 2.521-2.521V2.521A2.521 2.521 0 0 0 13.079 0zm.961 13.079a.961.961 0 0 1-.961.961H2.521a.961.961 0 0 1-.961-.961V2.521a.961.961 0 0 1 .961-.961h10.558a.961.961 0 0 1 .961.961z"
        class="cls-1 fillable"
      />
      <path
        id="Path_628"
        d="M18.58 12h-7.8a.78.78 0 0 0 0 1.56h7.8a.78.78 0 1 0 0-1.56zm0 3.432h-7.8a.78.78 0 1 0 0 1.56h7.8a.78.78 0 1 0 0-1.56z"
        class="cls-1 fillable"
        transform="translate(-6.881 -8.56)"
      />
      <path
        id="Path_629"
        d="M18.58 15.683h-7.8a.78.78 0 1 0 0 1.56h7.8a.78.78 0 1 0 0-1.56z"
        class="cls-1 fillable"
        transform="translate(-6.881 -5.083)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "ProductsList",
};
</script>

<style scoped>
.cls-1 {
  fill: #666;
}
</style>
