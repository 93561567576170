<template>
  <svg
    class="custom-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g id="_5419310061543238896" transform="translate(-1 -1)">
      <circle
        id="Ellipse_48"
        cx="10"
        cy="10"
        r="10"
        class="cls-1"
        transform="translate(2 2)"
      />
      <path
        id="Path_554"
        d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"
        class="cls-1"
      />
      <path
        id="Line_19"
        d="M0 0L0 0"
        class="cls-1"
        transform="translate(12 17)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "Help",
};
</script>

<style lang="scss" scoped>
.custom-icon {
  path,
  circle {
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    stroke: currentColor;
  }
}
</style>
