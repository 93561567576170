<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.808"
    height="17.808"
    viewBox="0 0 17.808 17.808"
  >
    <g id="_13176313511579838435" transform="translate(.1 .1)">
      <g id="Group_560" transform="translate(2.958 14.651)">
        <g id="Group_559">
          <path
            id="Path_541"
            d="M406.344 426a.344.344 0 1 1-.344.344.344.344 0 0 1 .344-.344z"
            class="cls-1"
            transform="translate(-406 -426)"
          />
        </g>
      </g>
      <g id="Group_562">
        <g id="Group_561">
          <path
            id="Path_542"
            d="M2.27 1.631v-.6A1.033 1.033 0 0 1 3.3 0h11.007a1.033 1.033 0 0 1 1.032 1.032v.6c2.243 4.143 2.27 4.02 2.27 4.215v.688A1.712 1.712 0 0 1 16.714 8v9.264a.344.344 0 0 1-.344.344H1.238a.344.344 0 0 1-.344-.344V8A1.731 1.731 0 0 1 0 6.534v-.687c0-.198.048-.113 2.27-4.216zM.926 5.5h2.128L4.2 2.063H2.817zm4.783.688H3.645v.344a1.032 1.032 0 1 0 2.063 0zm.053-.688l.573-3.439h-1.41L3.779 5.5zm8.889-4.471a.344.344 0 0 0-.344-.344H3.3a.344.344 0 0 0-.344.344v.344h11.695zM6.4 6.19v.344a1.032 1.032 0 0 0 2.063 0V6.19zm2.06-.69V2.063H7.032L6.459 5.5zm2.689 0l-.573-3.439H9.148V5.5zm-2 .688v.344a1.032 1.032 0 0 0 2.063 0V6.19zM13.83 5.5l-1.147-3.437h-1.41l.574 3.437zm-1.93.69v.344a1.032 1.032 0 1 0 2.063 0V6.19zm2.892-4.127h-1.384L14.555 5.5h2.128zm2.129 4.471V6.19h-2.27v.344a1.13 1.13 0 0 0 1.135 1.032 1.153 1.153 0 0 0 .449-.094 1.07 1.07 0 0 0 .686-.938zm-6.4 10.386h3.439v-2.957h-3.436zm0-3.645h3.439v-2.958h-3.436zm-8.939 3.646h8.254V9.974a.344.344 0 0 1 .344-.344h4.127a.344.344 0 0 1 .344.344v6.947h1.376V8.237a1.787 1.787 0 0 1-.241.017 1.9 1.9 0 0 1-1.255-.492 1.859 1.859 0 0 1-.207-.221 1.717 1.717 0 0 1-2.768.024 1.718 1.718 0 0 1-2.751 0 1.718 1.718 0 0 1-2.751 0 1.717 1.717 0 0 1-2.768-.024 1.862 1.862 0 0 1-.207.221 1.9 1.9 0 0 1-1.255.492 1.78 1.78 0 0 1-.241-.017zM.688 6.534a1.07 1.07 0 0 0 .711.948 1.143 1.143 0 0 0 .424.083 1.13 1.13 0 0 0 1.135-1.031V6.19H.688z"
            class="cls-1"
          />
        </g>
      </g>
      <g id="Group_564" transform="translate(2.27 9.63)">
        <g id="Group_563">
          <path
            id="Path_543"
            d="M266.344 283.645h.344v-3.3a.344.344 0 0 1 .344-.344h4.127a.344.344 0 0 1 .344.344v3.3h.344a.344.344 0 1 1 0 .688h-5.5a.344.344 0 1 1 0-.688zm1.032 0h3.439v-2.958h-3.439z"
            class="cls-1"
            transform="translate(-266 -280)"
          />
        </g>
      </g>
      <g id="Group_566" transform="translate(4.333 14.651)">
        <g id="Group_565">
          <path
            id="Path_544"
            d="M286.344 426h2.756a.344.344 0 0 1 0 .688h-2.751a.344.344 0 1 1 0-.688z"
            class="cls-1"
            transform="translate(-286 -426)"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "Retail",
};
</script>

<style scoped>
.cls-1 {
  fill: #666;
  stroke: #666;
  stroke-width: 0.2px;
}
</style>
