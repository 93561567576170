import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import "./plugins/base";
import "./plugins/vue2-google-maps";
import vuetify from "./plugins/vuetify";
import "./plugins/axios";
import "./plugins/vue-markdown-editor";
import firebase from "firebase/app";
import "firebase/firestore";
import axios from "axios";

Vue.config.productionTip = false;
Vue.prototype.$log = console.log;

async function auth_with_api(id_token) {
  const url = `${store.state.api_server_url}/login`;
  const {
    data: { access_token, user_id },
  } = await axios.post(url, { id_token });
  store.dispatch("UserStore/set_api_user_id", user_id);
  axios.defaults.headers.common["Authorization"] = `Bearer ${access_token}`;
}

let app;
firebase.auth().onAuthStateChanged(async (user) => {
  store.dispatch("UserStore/set_login_loading", true);
  try {
    if (user) {
      const id_token = await user.getIdToken();
      await auth_with_api(id_token);
      await store.dispatch("UserStore/set_user", user);

      const { claims } = await user.getIdTokenResult(true);
      const shop_id = claims["shop_id"];
      if (shop_id) {
        store.dispatch("RealtimeStore/connect", shop_id);
      }
      if (["/", "/login"].includes(window.location.pathname)) {
        const redirect_path = router.currentRoute.query?.next_url ?? null;
        const admin_redirect =
          redirect_path && redirect_path.startsWith("/admin");
        if (
          admin_redirect &&
          (claims["super_admin"] || claims["brand_manager"])
        ) {
          router.push(redirect_path);
        } else if (redirect_path && !admin_redirect && claims["shop_admin"]) {
          router.push(redirect_path);
        } else if (claims["shop_admin"]) {
          router.push("/");
        } else if (claims["shop_staff"]) {
          router.push("/staff_login");
        } else if (claims["super_admin"]) {
          router.push("/admin/brands");
        } else if (claims["brand_manager"]) {
          router.push({
            name: "admin-brands-id",
            params: { brand_id: claims["brand_id"] },
          });
        }
      }
    } else {
      await store.dispatch("StaffStore/clear_logged_in_staff");
      await store.dispatch("UserStore/set_user", user);
      store.dispatch("RealtimeStore/disconnect");
      delete axios.defaults.headers.common["Authorization"];
    }
  } catch (error) {
    firebase.auth().signOut();
    console.error(error);
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount("#app");
  }

  store.dispatch("UserStore/set_login_loading", false);
});
