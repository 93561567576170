<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    class="custom-icon"
  >
    <g transform="translate(-332 -305)">
      <path
        d="M139.8 143.3a5.531 5.531 0 0 1-2.283-.542 15.684 15.684 0 0 1-7.391-7.179 8.043 8.043 0 0 1-.616-1.688 3.671 3.671 0 0 1 1.508-3.95 1.082 1.082 0 0 1 1.752.308 21.2 21.2 0 0 1 1.519 2.357 1.471 1.471 0 0 1-.149 1.88 1.776 1.776 0 0 0 0 2.538 20.059 20.059 0 0 0 1.338 1.5 1.989 1.989 0 0 0 2.559.414 2.077 2.077 0 0 0 .372-.2 1.747 1.747 0 0 1 2.442.053 18.03 18.03 0 0 1 1.773 1.338 1.114 1.114 0 0 1 .149 1.731 3.338 3.338 0 0 1-2.973 1.44z"
        transform="translate(337.645 310.663) translate(-129.4 -129.626)"
      />
    </g>
  </svg>
</template>
<script>
export default {
  name: "Phone",
};
</script>
<style lang="scss" scoped>
.custom-icon {
  outline: none;
  g {
    path {
      fill: currentColor;
    }
  }
}
</style>
