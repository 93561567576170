import { orderBy } from "lodash";

// Constants

export const SERVICE_TYPES_INFO = {
  collection: {
    id: "collection",
    pretty_name: "Collection",
    pretty_name_sentence: "collection",
    icon: "mdi-store",
    tile_icon: "mdi-shopping",
  },
  delivery: {
    id: "delivery",
    pretty_name: "Delivery",
    pretty_name_sentence: "delivery",
    icon: "mdi-truck-delivery",
    tile_icon: "mdi-truck-delivery",
  },
  hospitality_delivery: {
    id: "hospitality_delivery",
    pretty_name: "Delivery",
    pretty_name_sentence: "delivery",
    icon: "mdi-moped",
    tile_icon: "mdi-moped",
  },
  in_house: {
    id: "in_house",
    pretty_name: "In House",
    pretty_name_sentence: "in house",
    icon: "mdi-silverware",
    tile_icon: "mdi-silverware",
  },
  takeaway: {
    id: "takeaway",
    pretty_name: "Takeaway",
    pretty_name_sentence: "takeaway",
    icon: "mdi-food",
    tile_icon: "mdi-food",
  },
  table_booking: {
    id: "table_booking",
    pretty_name: "Table Booking",
    pretty_name_sentence: "table booking",
    icon: "$table",
    tile_icon: "$table",
  },
};

export const DINING_SERVICE_TYPES = [
  SERVICE_TYPES_INFO.in_house.id,
  SERVICE_TYPES_INFO.takeaway.id,
  SERVICE_TYPES_INFO.hospitality_delivery.id,
];

export const RETAIL_SERVICE_TYPES = [
  SERVICE_TYPES_INFO.collection.id,
  SERVICE_TYPES_INFO.delivery.id,
];

export const RETAIL_SERVICE_TYPES_ITEMS = RETAIL_SERVICE_TYPES.map(function (
  service
) {
  return {
    text: SERVICE_TYPES_INFO[service].pretty_name,
    value: SERVICE_TYPES_INFO[service].id,
  };
});

// Functions

/**
 * Returns true if category is active for shop for selected service type otherwise false.
 * @param {object} categories_config - shops categories config object from store
 * @param {string} category - category id
 * @param {string} service_type - service type id
 */
export function is_category_active_for_service_id(
  categories_config,
  category,
  service_type
) {
  if (!category) return false;
  return [true, undefined].includes(
    categories_config[category]?.[service_type]
  );
}

/**
 * Filters an array of category ids to return only the categories
 * active for selected service.
 * @param {object} categories_config - shops categories config object from store
 * @param {array} shop_categories - array of shops active categories
 * @param {string} service_type - service id
 */
export function categories_active_for_service_id(
  categories_config,
  shop_categories,
  service_type
) {
  if (!service_type) return orderBy(shop_categories);
  return orderBy(
    shop_categories.filter((category) => {
      return is_category_active_for_service_id(
        categories_config,
        category,
        service_type
      );
    })
  );
}

/**
 * Filters shops active service types to return an array of active service
 * types objects for selected category.
 * @param {array} active_service_types - array of shops active service type objects
 * @param {object} categories_config - shop categories config object from store
 * @param {string} category - category id
 */
export function service_types_active_for_category_id(
  active_service_types,
  categories_config,
  category
) {
  if (!category) return active_service_types;
  return active_service_types.filter((service) => {
    return is_category_active_for_service_id(
      categories_config,
      category,
      service.id
    );
  });
}
